.feed {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-direction: column-reverse;
  padding: 10px 20px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.nonepermission {
  background: whitesmoke;
  align: center;
  text-align: center;
}

.more {
  height: 100px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item {
  flex-shrink: 0;
}

.more,
.item {
  transition: opacity 1000ms;
}

.bubble {
  margin: 10px 0;
  padding: 10px;
  max-width: 75%;
  overflow-wrap: break-word;
  min-height: 38px;
  min-width: 38px;
  border-radius: 20px;
}

/*.more:first-child,*/
.bubble:empty + .meta + .meta + .more,
.bubble:empty + .meta + .meta,
.bubble:empty + .meta,
.bubble:empty {
  opacity: 0;
}

.from {
  align-self: flex-start;
  margin: 10px;
  padding: 10px;
}

.in {
  align-self: flex-start;
  background: #e0e0e0;
}

.out {
  align-self: flex-end;
  background: #2196f3;
  color: white;
}

.draft {
  align-self: flex-end;
  background: #2196f3;
  color: white;
  opacity: 0.5;
}

.period {
  align-self: center;
}

.timestamp {
  margin: 25px 5px 10px 0;
  align-self: flex-end;
  color: #616161;
  font-size: 15px;
}

.monolog {
  margin: 25px 0 10px 5px;
  display: flex;
  flex-direction: column;
  color: #616161;
}

.monolog > :nth-child(1) {
  font-size: 15px;
  margin-bottom: 10px;
}

.monolog > :nth-child(2) {
  font-size: 13px;
  margin-bottom: 2px;
  text-transform: capitalize;
}

.monolog > :nth-child(3) {
  font-size: 10px;
}

.red > * {
  visibility: hidden;
}
