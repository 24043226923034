.outer {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.top {
  flex-shrink: 0;
}

.bottom {
  flex-shrink: 0;
}

.wrapper {
  display: flex;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

:global(.inside-iframe) .wrapper {
  padding: 2px;
}

.verified {
  display: none;
}

.logo {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

:global(.inside-iframe) .logo {
  display: none;
}

.lock {
  width: 44px;
  height: 44px;
  margin: 10px;
  border-radius: 999px;
  background: var(--app-border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.lock svg {
  fill: var(--workspace-lock-color);
  fill: white;
  height: 26px;
  width: 20px;
}

.textLogo {
  text-align: left;
  flex-grow: 1;
  height: 36px;
  color: var(--workspace-name-color);
  font-weight: var(--font-weight-bold);
  font-size: 28px;
}

.rest {
  flex-grow: 1;
  overflow: hidden;
}
