.outer {
  height: 100%;
}

.header {
  display: flex;
  width: 100%;
  padding: 0 20px;
  height: 70px;
}

.header.tablet {
  flex-wrap: wrap;
}

.header.mobile {
  padding: 0;
}

.tablet > :nth-child(1) {
  flex-basis: calc(100% - 400px);
  margin-right: auto;
}

.middle > :nth-child(1) {
  flex-basis: calc(100% - 335px);
}

.mobile > :nth-child(1) {
  order: 10;
  flex-basis: calc(100% - 6px);
  height: 48px;
  padding: 0 5px;
  padding-right: 10px;
  margin: 1px 0 1px 3px;
  cursor: pointer;
}

.tablet > :nth-child(2) {
  max-width: 50%;
  margin-left: 2px;
  order: 20;
}

.tablet > :nth-child(3) {
  max-width: calc(50% - 60px - 2px);
  order: 21;
}

.mobile > :nth-child(2) {
  max-width: calc(50% - 8px);
  margin-left: 10px;
}

.tablet > :nth-child(n + 2):nth-child(-n + 4) {
  height: 50px;
}

.tablet > :nth-child(4) {
  order: 22;
}

.tablet > .select:not(.choice) {
  margin-left: 5px;
}

.mobile > .select:not(.choice) {
  margin-left: 13px;
}

.tablet > .select:not(.choice) + * {
  max-width: calc(50% - 60px - 5px);
}

.mobile > :nth-child(1)::after {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 11px solid #2196f3;
  margin-left: 15px;
}

.mobile > :nth-last-child(2) {
  margin-left: auto;
}

.middle > :nth-child(n + 5) {
  padding: 16px 3px;
}

.middle > :nth-child(5) {
  padding-left: 0px;
}

.mobile > :nth-child(n + 5) {
  padding: 10px 3px 5px 3px;
}

.mobile > :nth-child(5) {
  margin-left: 8px;
}

.mobile > :nth-last-child(1) {
  margin-right: 8px;
}

.mobile > :nth-child(1) > :first-child {
  margin-right: 5px;
  box-sizing: border-box;
  background: #2196f3;
  border: solid 3px white;
}

.mobile > .active:nth-child(1) {
  border-radius: 999px;
  background: #eee;
}

.mobile > .active:nth-child(1) > :first-child {
  border-color: #eee;
}

.tablet > :nth-child(3) > * {
  margin: 12px 10px 0 0;
}

.middle .pill {
  font-size: 13px;
  padding: 0px 10px;
}

/*.mobile*/
.landscape > :nth-child(n + 1):nth-child(-n + 4) {
  visibility: hidden;
}

@media only screen and (max-width: 380px) {
  .mobile > :nth-child(5) {
    margin-left: 5px;
  }

  .pill {
    box-sizing: border-box;
    background: #2196f3;
    border: solid 5px white;
    line-height: 30px !important;
    padding: 0 5px !important;
  }
  .pill svg {
    color: white !important;
  }

  .pill.active {
    background: white !important;
    border: solid 5px #2196f3;
  }
  .pill.active svg {
    color: #2196f3 !important;
  }

  .pill > :first-child {
    margin: 0 !important;
  }
  .pill > :last-child {
    display: none;
  }
}

.name {
  flex-shrink: 1;
  min-width: 0;
  display: flex;
  height: 100%;
  padding-right: 15px;
  align-items: center;
  font-size: 18px;
}

.select {
  flex-shrink: 1;
  display: flex;
  height: 100%;
  padding-right: 15px;
  padding-right: 10px;
  align-items: center;
}

.select.choice {
  flex-shrink: 2;
  min-width: 100px;
}

.recipients {
  flex-shrink: 1;
  max-width: calc(100% - 650px);
  max-width: calc(100% - 700px);
  min-width: 60px;
  overflow: hidden;
  display: flex;
  height: 100%;
  padding: 0 7px;
  flex-wrap: wrap;
}

.more {
  flex-grow: 1;
  flex-shrink: 0;
  /* min-width: 50px; */
  width: 60px;
  display: flex;
  height: 100%;
  padding: 7px;
  align-items: center;
}

.more ~ * {
  flex-shrink: 0;
  padding: 16px 7px;
}

.header > :last-child {
  padding-right: 0 !important;
}

.icon:not(:empty) {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  margin-right: 10px;
  border-radius: 999px;
  background: #cbd5e0;
  background: #2196f3;
  color: #4a5568;
  color: white;
  font-weight: var(--font-weight-fat);
  text-transform: uppercase;
}

.zone {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #4a5568;
  font-weight: var(--font-weight-semi);
}

.container {
  position: absolute;
  z-index: 1;
  top: calc(100% + 9px);
  top: calc(100% + 0px);
  left: 50%;
  left: 0;
  /*transform: translate(-50%, 0);*/
  border-radius: 7px;
  background: white;
  box-shadow: 0 3px 25px 0px rgba(0, 0, 0, 0.1),
    0 1px 7px -3px rgba(0, 0, 0, 0.3);

  min-width: min(400px, calc(100vw - 30px));
  max-width: min(400px, calc(100vw - 30px));
  max-height: calc(var(--vh, 1vh) * 100 - 190px);

  padding: 10px;
  /*padding-right: 5px;*/

  overflow: auto;
}

.right {
  left: auto;
  right: 0;
}

.change {
  display: flex;
  padding: 0px 2px;
  height: 27px;
  align-items: center;
  border-radius: 999px;
  overflow: hidden;
}

.users:not(:last-child) {
  margin-left: 3px;
}

/*
.users svg {
  height: 21px;
  width: 21px;
  stroke: #616161;
  stroke-width: 1.5px;
}
*/

.group {
  margin: 0 10px;
  height: 22px;
  line-height: 22px;
  font-size: 14px;
  color: #616161;
  font-weight: var(--font-weight-semi);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.caret {
  line-height: 13px;
  color: #616161;
  margin-right: 2px;
}

.change.choice {
  background: #e8f0fe;
  cursor: pointer;
}
.change.choice:hover {
  background: #d0e0fc;
}
.change.choice:active {
  background: #b9d1fb;
}
.choice .group {
  color: #1967d2;
}
.choice .users svg {
  stroke: #1967d2;
}
.choice .caret {
  color: #1967d2;
}

.recipient {
  display: flex;
  margin: 22px 10px 0 0;
  flex-direction: column;
  justify-content: center;
  text-transform: capitalize;
  border-radius: 999px;
  height: 27px;
  padding: 0px 8px;
  color: #6232ec;
  color: #616161;
  white-space: nowrap;
  background: #f3e8fd;
  background: #eceff1;
  font-size: 10px;
  overflow: hidden;
}

.recipient > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.recipient :nth-child(2) {
  font-size: 9px;
  color: #757575;
}

.recipient :first-child:last-child {
  font-size: 11px;
}

.count {
  align-items: center;
  background: white;
  border-radius: 999px;
  height: 27px;
  line-height: 27px;
  padding: 0px 8px;
  color: #1967d2;
  cursor: pointer;
  font-size: 13px;
  font-weight: var(--font-weight-semi);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.count:empty {
  display: none;
}

.count {
  background: #e8f0fe;
}
.count:hover {
  background: #d0e0fc;
}
.count:active {
  background: #b9d1fb;
}

.pill {
  height: 38px;
  border-radius: 19px;
  line-height: 38px;
  padding: 0 13px;
  cursor: pointer;
  font-size: 14px;
}

.pill span {
  font-weight: var(--font-weight-semi);
}

.dot {
  width: 38px;
  height: 38px;
  border-radius: 19px;
  line-height: 28px;
  padding: 5px;
  cursor: pointer;
  color: var(--accent-color);
}

.pill svg {
  color: var(--accent-color);
}

.pill > :not(:last-child) {
  margin-right: 7px;
}

.dot svg {
  width: 28px;
  height: 28px;
}

.dot [data-icon="menu"] {
  width: 28px;
  height: 28px;
  background: #2196f3;
  border-radius: 999px;
  padding: 3px;
  color: white;
}

.dot.active [data-icon="menu"] {
  background: #2196f3;
  background: white;
  color: white;
  color: #2196f3;
}

.dot [data-icon="notifications"] {
  width: 28px;
  height: 28px;
  background: #2196f3;
  border-radius: 999px;
  padding: 3px;
  color: white;
}

.dot.active [data-icon="notifications"] {
  background: #2196f3;
  background: white;
  color: white;
  color: #2196f3;
}

.dot [data-icon="more"] {
  width: 28px;
  height: 28px;
  background: #2196f3;
  border-radius: 999px;
  padding: 3px;
  color: white;
}

.dot.active [data-icon="more"] {
  background: #2196f3;
  background: white;
  color: white;
  color: #2196f3;
}

.pill:hover,
.dot:hover {
  background: #eee;
}

.active.pill,
.active.dot {
  background: var(--accent-color);
  color: white;
}

.active.pill:hover,
.active.dot:hover {
  background: var(--accent-color-dark);
}

.active.pill svg,
.active.dot svg {
  color: white;
}

.ringBell {
  background: #ff6e40 !important;
}

.ringBell svg {
  animation: ringBell 2s infinite;
  color: #ff6e40 !important;
  background: white !important;
}

.wrapper {
  position: relative;
}

@keyframes ringBell {
  0% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(30deg);
  }
  95% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/**
.header.high .bar {
  width: calc(100% - 100px);
}
**/

/**
.header.tall {
  padding: 0 10px;
}

.header.tall .bar {
  padding: 0 10px;
}  
**/

/**
.header.high > :not(:first-child) {
  padding: 16px 2px;
}
**/
