@keyframes progress {
  0% {
    background-position: 1em 0;
  }
  100% {
    background-position: 0 0;
  }
}
@keyframes scale-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-out {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes rotate-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3) rotate(360deg);
    transform: scale(0.3) rotate(360deg);
  }
  60% {
    -webkit-transform: scale(1.3) rotate(144deg);
    transform: scale(1.3) rotate(144deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
}
@keyframes diagonal-slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes drop-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.reacticon {
  position: relative;
  display: inline-block;
}
.reacticon__type path,
.reacticon__type circle,
.reacticon__type rect {
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
}
.reacticon__label {
  bottom: 0.6em;
  display: inline-block;
  font-weight: bold;
  left: 0;
  line-height: 1;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
}
.reacticon__progress {
  border-radius: 0.25em;
  bottom: 0.9em;
  height: 0.5em;
  left: 50%;
  margin: 0 0 0 -30%;
  overflow: hidden;
  position: absolute;
  width: 60%;
}
.reacticon__progress__fill {
  border-radius: inherit;
  height: inherit;
}
.is-processing .reacticon__progress__fill {
  -webkit-animation: progress 1s linear infinite;
  animation: progress 1s linear infinite;
  background-image: -webkit-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.5) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.5) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1em 1em;
  width: 100%;
}
.is-animated .reacticon__label {
  opacity: 0;
  -webkit-animation: scale-out 0.2s ease-in 0.3s forwards;
  animation: scale-out 0.2s ease-in 0.3s forwards;
}
.is-animated .reacticon__text_row-1 {
  -webkit-animation: scale-in 0.1s ease-in;
  animation: scale-in 0.1s ease-in;
}
.is-animated .reacticon__text_row-2 {
  -webkit-animation: scale-in 0.2s ease-in;
  animation: scale-in 0.2s ease-in;
}
.reacticon__text_row-3 {
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s;
}
.animate .reacticon__text_row-3 {
  opacity: 0;
  transform: scale(0.3);
}
.reacticon__text_row-4 {
  opacity: 1;
  transform: scale(1);
  transition: all 0.4s;
}
.animate .reacticon__text_row-4 {
  opacity: 0;
  transform: scale(0.3);
}
.is-animated .reacticon__table_row-1 {
  -webkit-animation: drop-in 0.1s ease-in;
  animation: drop-in 0.1s ease-in;
}
.is-animated .reacticon__table_row-2 {
  -webkit-animation: drop-in 0.15s ease-in;
  animation: drop-in 0.15s ease-in;
}
.is-animated .reacticon__table_row-3 {
  -webkit-animation: drop-in 0.2s ease-in;
  animation: drop-in 0.2s ease-in;
}
.is-animated .reacticon__table_row-4 {
  -webkit-animation: drop-in 0.25s ease-in;
  animation: drop-in 0.25s ease-in;
}
.is-animated .reacticon__table_row-5 {
  -webkit-animation: drop-in 0.3s ease-in;
  animation: drop-in 0.3s ease-in;
}
.is-animated .reacticon__table_row-6 {
  -webkit-animation: drop-in 0.35s ease-in;
  animation: drop-in 0.35s ease-in;
}
.is-animated .reacticon__table_row-7 {
  -webkit-animation: drop-in 0.4s ease-in;
  animation: drop-in 0.4s ease-in;
}
.is-animated .reacticon__code_row-1 {
  -webkit-animation: diagonal-slide-in 0.1s ease-in;
  animation: diagonal-slide-in 0.1s ease-in;
}
.is-animated .reacticon__code_row-2 {
  -webkit-animation: diagonal-slide-in 0.15s ease-in;
  animation: diagonal-slide-in 0.15s ease-in;
}
.is-animated .reacticon__code_row-3 {
  -webkit-animation: diagonal-slide-in 0.2s ease-in;
  animation: diagonal-slide-in 0.2s ease-in;
}
.is-animated .reacticon__code_row-4 {
  -webkit-animation: diagonal-slide-in 0.25s ease-in;
  animation: diagonal-slide-in 0.25s ease-in;
}
.is-animated .reacticon__code_row-5 {
  -webkit-animation: diagonal-slide-in 0.3s ease-in;
  animation: diagonal-slide-in 0.3s ease-in;
}
.is-animated .reacticon__code_row-6 {
  -webkit-animation: diagonal-slide-in 0.35s ease-in;
  animation: diagonal-slide-in 0.35s ease-in;
}
.is-animated .reacticon__code_row-7 {
  -webkit-animation: diagonal-slide-in 0.4s ease-in;
  animation: diagonal-slide-in 0.4s ease-in;
}
.is-animated .reacticon__code_sidebar {
  -webkit-animation: scale-in 0.2s ease-in;
  animation: scale-in 0.2s ease-in;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
}
.is-animated .reacticon__code_numbers {
  -webkit-animation: scale-in 0.4s ease-in;
  animation: scale-in 0.4s ease-in;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
}
.is-animated .reacticon__image_bg {
  -webkit-animation: scale-in 0.1s ease-in;
  animation: scale-in 0.1s ease-in;
}
.is-animated .reacticon__image_front-mtn {
  -webkit-animation: scale-in 0.3s ease-in;
  animation: scale-in 0.3s ease-in;
}
.is-animated .reacticon__image_rear-mtn {
  -webkit-animation: scale-in 0.2s ease-in;
  animation: scale-in 0.2s ease-in;
}
.is-animated .reacticon__image_sun {
  -webkit-animation: scale-in 0.4s ease-in;
  animation: scale-in 0.4s ease-in;
}
.is-animated .reacticon__slides_bg {
  -webkit-animation: scale-in 0.1s ease-in;
  animation: scale-in 0.1s ease-in;
}
.is-animated .reacticon__slides_pie {
  -webkit-animation: rotate-in 0.35s ease-out;
  animation: rotate-in 0.35s ease-out;
}
.is-animated .reacticon__slides_slice {
  opacity: 0;
  -webkit-animation: diagonal-slide-in 0.2s ease-out 0.2s forwards;
  animation: diagonal-slide-in 0.2s ease-out 0.2s forwards;
}
