.outer {
  padding: 20px;
  display: flex;
  /*
  height: 100%;
  border-radius: 10px;
  background: #e8edf0;
  background: #e9ecee;
  border: solid 1px #dde2e5;
  */
}

.list {
  max-width: 400px;
  padding: 20px;
}

.zone {
  --height: 45px;
  --border-radius: 10px;
  height: var(--height);
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
  border-radius: var(--border-radius);
  /*
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
  */
  background: white;
}

.zone .left,
.zone .right {
  display: flex;
  align-items: center;
}

.zone .left {
  height: var(--height);
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  flex-grow: 1;
}

.zone .right {
  height: var(--height);
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.zone .left .type {
  margin: 10px;
}

.zone .right .check {
  margin: 10px;
}

.item {
  width: 100px;
  height: 100px;
  border: solid 2px red;
}
