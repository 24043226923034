.slits {
  display: flex;
  flex-direction: row;
  margin-top: -20px;
  margin-bottom: -50px;
}

.slits > :last-child {
  padding: 10px 20px 0 30px;
  overflow: hidden;
}

.slits > :last-child > div:first-child {
  margin-bottom: 5px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: var(--font-weight-semi);
}

.dialogButton {
  position: relative;
  z-index: 1;
  padding: 1rem;
  border-radius: 0.375rem;
  float: right;
  background: #3b82f6;
  color: #f3f4f6;
  font-weight: 600;
}

.dialogButton:hover {
  background: #1d4ed8;
}

.slits :global .bp3-datepicker {
  box-shadow: none;
  background: #f5f5f5;
  border-radius: 15px;
}
