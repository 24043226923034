.wizard {
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 4px;
}

@media (max-width: 850px) {
  .wizard {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
}

.wizard > div {
  max-height: 800px;
  width: 100%;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid rgba(17, 20, 24, 0.15);
  text-transform: capitalize;
  min-height: 40px;
  padding: 5px 10px 5px 10px;
}

.title > div {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.title h5 {
  margin-left: 7.5px;
}

.title > :last-child {
  cursor: pointer;
  color: #5f6b7c;
}

.select {
  flex-basis: 100%;
  margin-bottom: 10px;
}

.first,
.second,
.third {
  background: #f6f7f9;
  padding: 25px 30px;
}

.second,
.third {
  height: 400px;
  overflow: auto;
}

.third [icon="user"] {
  color: #ddd;
}

.search {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0),
    inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
  padding: 4px 4px;
  border-radius: 4px;
}

.search input {
  width: 100%;
  padding: 5px 10px;
  outline: none;
}

.search input::placeholder {
  color: #5f6b7c;
}

.search [icon="search"] {
  color: #5f6b7c;
  margin-left: 10px;
}

.search [icon="cross"] {
  color: #5f6b7c;
  overflow: hidden;
  padding: 4px;
  cursor: pointer;
}

.alert {
  display: flex;
  margin: 0px 0px 10px 5px;
}

.alert [icon="people"] {
  margin-right: 6px;
  color: #2b95d6;
}

.expand {
  height: 400px;
  overflow: auto;
}

.form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0px 16px;
  max-width: 100%;
}

.form input {
  border: none;
  background: #e8ebee;
  margin-bottom: 10px;
  padding: 10px 14px;
  font: inherit;
  color: black;
}

.form input:focus {
  border-bottom: 2px solid #8f8f8f;
}

.form input:focus.invalid {
  outline: none;
  border-bottom: 2px solid #d32f2f;
}

.form label {
  flex-wrap: nowrap;
  color: #5f6b7c;
  margin-bottom: 5px;
  align-self: flex-start;
}

.signOrder:first-child {
  display: flex;
  justify-content: center;
  padding: 15px;
}

.signOrder span {
  margin-right: 10px;
}

.signOrderDisabled {
  color: #bbb;
}

.orderAndName {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  gap: 10px;
  align-items: center;
}

.orderAndName div {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 0;
  margin: 0;
}

.orderAndName div span {
  font-weight: var(--font-weight-bold);
}

.orderAndName span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  justify-self: start;
  font-weight: var(--font-weight-bold);
}

.orderAndName :last-child {
  justify-self: end;
}

.orderAndName [icon="user"] {
  color: #2b95d6;
}

.indicatorWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicator {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.indicator.active {
  opacity: 1;
}

.indicator.finish {
  background-color: #2b95d6;
}

.calender {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  min-height: 200px;
  background: #f6f7f9;
}

.calender :global .bp3-datepicker {
  width: min-content;
  margin: 25px 0px 0px 0px;
}

.date {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  margin: 20px;
}

.date span {
  color: #5f6b7c;
}

.selected {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.selected p {
  background-color: rgba(143, 153, 168, 0.15);
  color: #1c2127;
  margin: 5px 0;
  padding: 2px 6px;
  border-radius: 2px;
  font-weight: var(--font-weight-semi);
  width: fit-content;
}

.deadline p {
  background: #137cbd;
  color: #fff;
}

.notSelected p {
  background-color: rgba(143, 153, 168, 0.15);
  color: #1c2127;
}

.step {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid rgba(17, 20, 24, 0.15);
  padding: 10px;
  border-radius: 0 0 4px 4px;
}

.step div:nth-child(2) {
  display: flex;
  align-items: center;
}

.next {
  margin-left: 10px;
  background-color: #2b95d6;
  color: #fff;
}

.next:hover {
  background: #1d6a9a;
}

.next span {
  font-weight: var(--font-weight-bold);
}

.next,
.back,
.attestation,
.submit {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  padding: 5px 10px;
  text-align: left;
  vertical-align: middle;
  min-height: 30px;
  min-width: 30px;
}

.next.disabled {
  cursor: not-allowed;
  color: hsla(0, 0%, 100%, 0.6);
  background-color: rgba(45, 114, 210, 0.5);
}

.back {
  background: #e7eaee;
  color: #1c2127;
}

.back:hover {
  background-color: #cfd5dd;
}

.back span {
  font-weight: var(--font-weight-bold);
}

.mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.mobileBtn {
  min-height: 36px;
  width: 100%;
}

.mobileSelect {
  min-height: 200px;
}

.submit {
  margin-left: 10px;
}

.submit svg {
  margin-right: 6px;
}

.submit span {
  font-weight: var(--font-weight-bold);
}

.attestation {
  color: #2b3051;
  background: #7bdcb5;
}

.attestation:hover {
  background: #00d084;
}

.signature {
  color: black;
  background: #ffc107;
}

.signature:hover {
  background: #ffa000;
}

.info {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 16px;
  max-width: 100%;
  align-items: center;
}

.info > div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: 2px solid #f0f0f0;
  margin: 5px 0px 10px 0px;
  color: #1c2127;
  padding: 6px 10px;
  border-radius: 2px;
  width: fit-content;
}

.info > div span {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: var(--font-weight-bold);
}

.info > div [icon="user"] {
  margin-right: 6px;
  color: #2b95d6;
}

.info label {
  color: #5f6b7c;
  margin-bottom: 5px;
  align-self: flex-start;
}

.info input {
  border: none;
  background: #e8ebee;
  margin-bottom: 10px;
  padding: 10px 14px;
  font: inherit;
}

.info input:focus {
  border-bottom: 2px solid #8f8f8f;
}

.card {
  display: flex;
  align-items: center;
  padding: 16px;
  background: #fff;
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
    0 1px 1px rgba(16, 22, 26, 0.2);
  border-style: solid;
  border-color: rgb(68, 76, 86);
}

.card span {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: var(--font-weight-bold);
}

.icon {
  margin-right: 10px;
  color: #ddd;
}

.icon.selected {
  color: #2b95d6;
}
