.wrapper {
  position: absolute;
  top: -10px;
  left: -10px;
  padding: 1px;
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 0 3px 25px 0px rgba(0, 0, 0, 0.1),
    0 1px 7px -3px rgba(0, 0, 0, 0.3);
}

.wrapper ul:empty {
  display: none;
}

.wrapper {
  opacity: 0;
  transition: opacity 100ms;
}

.active {
  opacity: 1;
}

.wrapper li {
  text-transform: capitalize;
}

.hidden-upload {
  display: block;
  visibility: hidden;
  height: 0;
}

.primary div {
  font-weight: var(--font-weight-bold);
  color: #0f172a;
}

.primary svg {
  color: #0f172a;
}

.esignature svg {
  height: 20px;
  width: 20px;
  margin-right: 3px;
  stroke: #f9a825;
  stroke-width: 2;
}

.beta {
  margin-left: 5px;
}
