.outer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bar {
  display: flex;
  height: 50px;
  padding: 6px;
  color: #4a5568;
}

.header {
  display: flex;
  height: 38px;
  padding: 10px 10px 0 10px;
  flex-grow: 5;
  flex-basis: 0;
  font-weight: var(--font-weight-fat);
}

.rest {
  flex: 1 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty {
  display: flex;
  flex-direction: column;
  color: #263238;
  text-align: center;
}

.empty > :first-child {
  color: #ff5252;
  color: #2196f3;
}

.empty > :last-child {
  color: #757575;
  font-size: 13px;
}

.empty > :last-child > :first-child {
  color: #212121;
  padding: 15px 0 10px 0;
  font-size: 19px;
  font-weight: var(--font-weight-bold);
}
