.dialog {
  display: flex;
  flex-direction: column;
  padding: 20px 6px 6px;
  width: 800px;
  max-height: calc(100vh - 60px);
}

.dialog .dialogBody {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.dialog .scrollable {
  flex-grow: 1;
  overflow-y: auto;
}

.dialog :global(.bp3-dialog-header) {
  padding-left: 15px;
}

.title {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.title > * + * {
  margin-left: 15px;
}

.quickIcon {
  display: flex;
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  background: #2979ff;
}

.quickIcon :global .bp3-icon {
  margin-right: 0;
  color: white;
}

.dialogTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dialogBody {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
}

.memberList {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 400px;
}

.tableTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  padding: 0 12px 12px 12px;
  align-items: center;
}

.tableTopButton {
  flex-grow: 0;
  flex-shrink: 0;
  user-select: none;
  border-radius: 0.375rem;
  background: #3b82f6;
  color: #f3f4f6;
  font-weight: 600;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.search {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 999px;
  background: #d1d5db;
  background: #e2e8f0;
  color: #374151;
  overflow: hidden;
}

.search input {
  width: 100%;
  padding: 0 10px;
  background: transparent;
}

.search > :first-child {
  margin-left: 13px;
  pointer-events: none;
}

.search > :last-child {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 999px;
  cursor: pointer;
}

.search > :last-child:hover {
  background: #d1d5db;
}

.dialogBottomRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0.75rem;
}

.dialogInput {
  background: #e5e7eb;
  background: #edf2f7;
  display: block;
  padding: 1.25rem;
  width: 100%;
  border-radius: 0.5rem;
  appearance: none;
  line-height: 1.5;
  color: #1f2937;
  font-weight: 400;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.dialogInput:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.dialogInput::placeholder {
  color: #4b5563;
}

.dialogButton {
  align-self: flex-end;
  user-select: none;
  padding: 1rem;
  border-radius: 0.375rem;
  float: right;
  background: #3b82f6;
  color: #f3f4f6;
  font-weight: 600;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}
.dialogButton:hover {
  background: #2563eb;
}
.dialogButton:active {
  background: #1d4ed8;
}

.dialogButton.success {
  background: #22c55e;
}
.dialogButton.success:hover {
  background: #16a34a;
}
.dialogButton.success:active {
  background: #15803d;
}

.dialogButton.danger {
  background: #ef4444;
}
.dialogButton.danger:hover {
  background: #dc2626;
}
.dialogButton.danger:active {
  background: #b91c1c;
}

.dialogButton.neutral {
  background: #cbd5e1;
  color: #1e293b;
}
.dialogButton.neutral:hover {
  background: #b0bccd;
}
.dialogButton.neutral:active {
  background: #94a3b8;
}

.dialogButton:disabled {
  background: #dddddd;
}

.dialogButton.full {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dialogButton.full span {
  font-weight: 600;
}

.errorMessage {
  color: red;
  visibility: hidden;
}

.errorMessage.show {
  visibility: visible;
}

.errorInput {
  box-shadow: 0 0 0 4px #ff5252;
}

.error2fa {
  pointer-events: none;
  opacity: 0.5;
}

.select {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.select select {
}

.moreOptions {
  align-self: flex-end;
  flex: 0 1 auto;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  user-select: none;
  border-radius: 999px;
  background: #e8f0fe !important;
  color: #1967d2 !important;
  font-weight: var(--font-weight-semi);
  cursor: pointer;
}
.moreOptions > :first-child {
  margin-right: 10px;
  vertical-align: -2px;
}
.moreOptions:hover {
  background: #d0e0fc !important;
}
.moreOptions:active {
  background: #b9d1fb !important;
}

.label {
  font-size: 14px;
  padding: 0 0 5px 8px;
}

.select {
}

.checkbox {
  flex: 0 1;
  align-self: flex-start;
  overflow: hidden;
  border-radius: 999px;
  background: #ffecb3;
  font-size: 14px;
  height: 30px;
  margin-left: 5px;
  margin-right: 20px;
  padding-right: 30px;
  padding-left: 10px;
}

.checkbox:hover {
  background: #ffe082;
}

.checkbox:active {
  background: #ffd54f;
}

.checkbox > span {
  height: 30px;
  line-height: 30px;
  color: #ff6f00;
}

.checkbox > :nth-last-child(n + 3) {
  font-size: 11px;
  margin-right: 7px;
  color: #f57f17;
  font-weight: var(--font-weight-bold);
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
}

.checkbox > :nth-last-child(3) {
  font-weight: var(--font-weight-semi);
  color: #e65100;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkbox :global .checkmark {
  width: 22px;
  height: 22px;
  top: 4px;
  right: 6px;
  left: auto;
  border-radius: 8px;
  background: white !important;
}
.checkbox :global .checkmark:after {
  top: 2px;
  left: 6px;
  border-color: #ef6c00;
}

.checkboxes {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  font-size: 20px;
}

.checkboxes > * {
  flex-basis: 50%;
}

.checkboxes span {
  font-size: 18px;
}

.checkboxes > :nth-child(n + 3) {
  margin-top: 15px;
}

:global .checkbox input ~ .checkmark:after {
  display: block;
  transform: rotate(40deg) scale(0);
  transition: transform 200ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
}

:global .checkbox input:checked ~ .checkmark:after {
  transform: rotate(40deg) scale(1);
}

.br {
  flex-basis: 100%;
}

.pill {
  display: flex;
  flex: 0 1 auto;
  align-self: flex-start;
  overflow: hidden;
  border-radius: 999px;
  background: #ffecb3;
  font-size: 14px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
}

.pill > * {
  color: #ff6f00;
}

.pill > :nth-last-child(n + 2) {
  padding-left: 10px;
  font-size: 11px;
  color: #f57f17;
  font-weight: var(--font-weight-bold);
  letter-spacing: 1px;
  text-transform: uppercase;
}

.pill > :last-child {
  font-weight: var(--font-weight-semi);
  padding: 0 10px;
  /*background: #FFE082;*/
  color: #e65100;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.row {
  display: flex;
  align-items: center;
  height: 50px;
  /*margin: 0 6px;*/
  /*padding: 0 10px;*/
  border-radius: 6px;
  cursor: pointer;
}

.row:hover {
  background: #edf2f7;
  background: #e2e8f0;
}

.row > * + * {
  /*margin-left: 10px;*/
}

.img {
  align-self: auto;
  width: 80px;
  height: 100%;
  object-fit: cover;
}

.provider {
  height: 28px;
  display: flex;
}

.provider span {
  display: block;
  height: 28px;
  line-height: 28px;
  padding: 0 8px;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 999px;
  background: #eeeeee;
  color: #424242;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.provider-bankid span,
.provider-2fa span {
  background: #69f0ae;
  color: #1b5e20;
}

.role {
  height: 28px;
  display: flex;
}

.role span {
  display: block;
  height: 28px;
  line-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: 999px;
  background: #eeeeee;
  color: #424242;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.column.provider span,
.column.role span {
  margin-left: -5px;
}

.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: capitalize;
  height: 40px;
}

.user > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user > * + * {
  margin-top: 3px;
}

.user :last-child {
  font-size: 11px;
  color: #757575;
}

.user :first-child {
  font-size: 12px;
  color: #212121;
  font-weight: var(--font-weight-semi);
}

.user :first-child:last-child {
  font-size: 13px;
}

.check {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
}

.check :global(.bp3-switch) {
  margin: 0 !important;
  padding: 0 !important;
}
.check :global .bp3-control-indicator {
  margin-left: 0 !important;
  margin-right: 0 !important;
  outline: 0 !important;
}

.check :global .bp3-switch input:checked ~ .bp3-control-indicator {
  background-color: #66bb6a !important;
}

.check :global .bp3-switch input:checked:disabled ~ .bp3-control-indicator {
  background-color: #c8e6c9 !important;
}

.selectAllButton {
  display: flex;
  align-self: center;
}

.upsideDown {
  transform: rotate(180deg);
}

.bar {
  display: flex;
  height: 40px;
  /*padding: 0px 6px 0px 6px;*/
  color: #4a5568;
}

.header {
  display: flex;
  height: 38px;
  padding: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  font-weight: var(--font-weight-fat);
  align-items: center;
  overflow: hidden;
}

.header:hover {
  border-radius: 6px;
  background: #edf2f7;
  cursor: pointer;
}

.disabled {
  color: whitesmoke;
}

.header:nth-child(-n + 2) {
  flex-basis: 150%;
}

.header:last-child {
  flex-basis: 50%;
}

.header > :first-child {
  font-weight: inherit;
  flex-grow: 1;
}

.selectZoneHeader {
  display: flex;
  height: 38px;
  padding: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  font-weight: var(--font-weight-fat);
  align-items: center;
  overflow: hidden;
}

.selectZoneHeader:hover {
  border-radius: 6px;
  background: #edf2f7;
  cursor: pointer;
}

.disabled {
  color: whitesmoke;
}

.selectZoneHeader:nth-child(-n + 2) {
  flex-basis: 150%;
}

.selectZoneHeader:last-child {
  flex-basis: 100%;
}

.selectZoneHeader > :first-child {
  font-weight: inherit;
  flex-grow: 1;
}

.selectZoneColumn {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding: 0 10px; */
}

.selectZoneNameColumn {
  flex-basis: 150%;
  font-weight: var(--font-weight-semi);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selectZoneOwnerColumn {
  flex-basis: 100%;
  font-weight: var(--font-weight-semi);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 20px;
  text-transform: capitalize;
}

.column {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
}

.column:nth-child(-n + 2) {
  flex-basis: 150%;
}

.column:last-child {
  flex-basis: 50%;
}

.margin {
  padding: 4px 0;
}

.disabledZone {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  opacity: 0.5;
}

.zone {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

.zone:hover {
  background: #e2e8f0;
}

.avatar {
  flex-shrink: 0;
  --size: 36px;
  margin: 7px;
  border-radius: 999px;
  width: var(--size);
  height: var(--size);
  line-height: var(--size);
  text-align: center;
  background: #2196f3;
  color: white;
  font-weight: var(--font-weight-fat);
  text-transform: uppercase;
  font-size: 16px;
}

.template .avatar {
  background: #263238;
}

.current .avatar {
  background: white;
  color: #2196f3;
}

.label {
  flex-grow: 1;
  font-weight: var(--font-weight-semi);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icons {
  margin: 10px;
  color: #4a5568;
}

.icons > * {
  display: inline-block;
  vertical-align: text-bottom;
  background: #e2e8f0;
  overflow: hidden;
  border: solid 3px #e2e8f0;
}

.icons > :first-child {
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
}

.icons > :last-child {
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
}

.icons svg {
  max-height: 14px;
  max-width: 14px;
  margin: 1px;
}

svg.users {
  margin: 0;
  stroke-width: 2px;
  max-height: 16px;
  max-width: 16px;
}

.dialogHeading {
  font-size: 24px;
}

.dialogSubHeading {
  font-size: 16px;
  color: #292929;
}
.dialogSubList {
  display: flex;
  flex-direction: column;
}
