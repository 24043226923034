.Outer {
  --navbar-height: 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
}

.Outer,
.Outer * {
  box-sizing: border-box;
}

.Navbar {
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  height: 60px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 12px;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  gap: 10px;
}

.Expander {
  flex-grow: 1;
}

.Title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 600px) {
  .Title {
    max-width: 100px;
  }
}

.Button {
  user-select: none;
  height: 34px;
  padding: 0 10px;
  /*padding-top: 4px;*/
  border-radius: 13px;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  color: #263238;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: #eee;
}

.Button > :first-child:not(:last-child) {
  height: 16px;
  width: 16px;
}

.Button > :last-child {
  height: 16px;
  font-size: 12px;
  /*line-height: 20px; <- this looks good in vite*/
  line-height: 16px;
}

.Button:hover {
  background: #ddd;
}

.Button:active {
  background: #ccc;
}

.Button.Primary {
  color: white;
  fill: white;
  background: #2962ff;
}

.Button.Primary:hover {
  background: #304ffe;
}

.Button.Primary:active {
  background: #3f51b5;
}

.Button.Warning {
  color: #3e2723;
  fill: #3e2723;
  background: #ffc107;
}

.Button.Warning:hover {
  background: #ffb300;
}

.Button.Warning:active {
  background: #ffa000;
}

.Button.Attestation {
  background: #7bdcb5;
  color: #2b3051;
}

.Button.Attestation:hover {
  background: #00d084;
}

.Disabled {
  fill: #ccc;
  background: #eee;
  cursor: not-allowed;
}

.Info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  max-width: 40rem;
  margin: 0 auto;
  color: #935610;
  font-weight: var(--font-weight-semi);
}

.Icon {
  margin-bottom: 10px;
}

.Bold {
  font-weight: var(--font-weight-bold);
}

.Error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Error > span {
  display: block;
  margin-top: 5px;
}

.Nav {
  display: flex;
  border-radius: 999px;
  gap: 2px;
  background: #eeeeee;
}

.Nav:not(:hover) {
  --divcol: #cfcfcf;
  --divmar: 6px;
  background: linear-gradient(
    to bottom,
    #eeeeee 0,
    #eeeeee var(--divmar),
    var(--divcol) var(--divmar),
    var(--divcol) calc(100% - var(--divmar)),
    #eeeeee calc(100% - var(--divmar)),
    #eeeeee 100%
  );
}

.Nav > * {
  user-select: none;
  height: 34px;
  padding-left: 10px;
  padding-right: 9px;
  /*padding-top: 4px;*/
  border-radius: 13px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  color: #263238;
  background: #eee;
}

.Nav > :first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Nav > :last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Nav > :hover {
  background: #ddd;
}

.Nav > :active {
  background: #ccc;
}

.Nav > * > * {
  height: 16px;
  width: 16px;
}

.Zoom > * > * {
  transform: scale(0.85);
}

.Inner {
  /*flex: 1;*/
  height: calc(100% - 60px);
  display: flex;
}

.Sidebar {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  background: white;
  flex: 0 0 300px;
  overflow: auto;
}

.Progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  color: black;
}

.Main {
  /*flex: 1;*/
  width: 100%;
}

.Main:not(:first-child) {
  width: calc(100% - 300px);
}

.NSon100p,
.NSon100p > * {
  height: 100%;
}

.Toggle {
  display: none;
}

.Toggle + * {
  position: absolute;
  z-index: 9999;
  top: calc(var(--navbar-height) + 10px);
  left: -100px;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .Main {
    width: 100% !important;
  }

  .Toggle + * {
    left: 10px;
  }

  .Sidebar {
    flex: 0 0 0;
  }

  .Toggle:checked ~ .Sidebar {
    flex: 0 0 100vw;
  }
}

.AViewer {
  height: 100%;
  width: 100%;
}

.Sidebar > :first-child {
  position: absolute;
  top: 10px;
  right: 10px;
  user-select: none;
  height: 28px;
  padding: 0 6px;
  border-radius: 9px;
  display: flex;
  gap: 2px;
  align-items: center;
  cursor: pointer;
  color: #263238;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: #eee;
  background: transparent;
}

.Sidebar > :first-child > :not(:last-child) {
  height: 16px;
  width: 16px;
}

.Sidebar > :first-child > :last-child {
  height: 16px;
  font-size: 11px;
  /*line-height: 18px; <- this looks good in vite*/
  line-height: 16px;
}

.Sidebar > :first-child:hover {
  background: #ddd;
}

.Sidebar > :first-child:active {
  background: #ccc;
}

.Toggle + * {
  user-select: none;
  height: 28px;
  padding: 0 6px;
  border-radius: 9px;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  color: #263238;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.Toggle:not(:checked) + * {
  background: #0000000d;
}

.Toggle + * > :not(:last-child) {
  height: 16px;
  width: 16px;
  transform: scale(0.85);
}

.Toggle + * > :last-child {
  height: 16px;
  font-size: 11px;
  /*line-height: 18px; <- this looks good in vite*/
  line-height: 16px;
}

.Toggle + * > :last-child::before {
  display: block;
  content: "MORE";
}

.Toggle + :hover {
  background: #ddd;
}

.Toggle + :active {
  background: #ccc;
}

.Toggle:not(:checked) + * > :first-child {
  display: none;
}

.Toggle:checked + * > :not(:first-child):not(:last-child) {
  display: none;
}

.Toggle:checked + * > :last-child::before {
  content: "HIDE";
}

@media only screen and (max-width: 850px) {
  .Button > :last-child {
    display: none;
  }
}

/*
.Toggle + * {
  padding: 10px 5px 0 5px;
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition: opacity 0.15s linear, filter 0.15s linear, left 0.15s ease;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.Toggle + :hover {
  opacity: 0.7;
}
.Toggle:checked + :hover {
  opacity: 0.7;
}

.Toggle:checked + * > * > *,
.Toggle:checked + * > * > ::before,
.Toggle:checked + * > * > ::after {
  background-color: #000;
}

.Toggle + * > * {
  width: 30px;
  width: 20px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.Toggle + * > * > * {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.Toggle + * > * > *,
.Toggle + * > * > ::before,
.Toggle + * > * > ::after {
  width: 30px;
  width: 20px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.Toggle + * > * > ::before,
.Toggle + * > * > ::after {
  content: "";
  display: block;
}
.Toggle + * > * > ::before {
  top: -8px;
}
.Toggle + * > * > ::after {
  bottom: -8px;
}

.Toggle:checked + * > * > ::before {
  transform: translate3d(-4px, 3px, 0) rotate(-45deg) scale(0.7, 1);
}

.Toggle:checked + * > * > ::after {
  transform: translate3d(-4px, -3px, 0) rotate(45deg) scale(0.7, 1);
}
*/

/*
.Sidebar:not(:empty)::after {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 30px;
  height: 30px;
  content: "✖";
  content: "✕";
  font-size: 30px;
  display: block;
  cursor: pointer;
}
*/

/*
.Sidebar:not(:empty)::before {
  position: absolute;
  top: 20px;
  right: 30px;
  width: 200px;
  height: 30px;
  content: "✕";
  font-size: 20px;
  display: block;
  cursor: pointer;
}

.Sidebar:not(:empty)::first-letter {
  color: red;
}
*/

/*
.Sidebar:not(:empty)::before,
.Sidebar:not(:empty)::after {
  position: absolute;
  top: 20px;
  right: 10px;
  content: "";
  display: block;
  height: 4px;
  width: 20px;
  background: #000;
  border-radius: 4px;
  cursor: pointer;
}

.Sidebar:not(:empty)::before {
  transform: rotate(45deg);
}
.Sidebar:not(:empty)::after {
  transform: rotate(-45deg);
}
*/

.Media {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
}

.Status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Status span {
  margin: 5px;
  font-weight: var(--font-weight-bold);
}
