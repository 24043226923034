.mobile .center {
  width: 400px;
  transform: scale(0.75);
}

.mobile .method {
  width: 400px;
}

.mobile .method.active {
  width: 420px;
}

.mobile .method .name {
  font-size: 20px;
}

.mobile .column:first-child:not(:last-child),
.mobile .column:last-child:not(:first-child) {
  justify-content: center;
}

.mobile .column:first-child:not(:last-child) > :nth-child(-n + 2),
.mobile .column:last-child:not(:first-child) > :nth-child(-n + 2) {
  width: 130%;
  transform: translate(5px, -25px) scale(0.75);
}

.canvas {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bar {
  height: 70px;
  flex-shrink: 0;
  background: white;
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  justify-content: space-between;
}

.companyLogo {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  animation: example 0.4s;
}

.companyLogo span {
  margin-right: 3px;
  color: grey;
  font-weight: var(--font-weight-bold);
}

.companyLogo a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.companyLogo svg {
  margin-right: 10px;
}

.companyLogo img {
  height: 30px;
  width: auto;
}

.yourlogo {
  display: flex;
  font-size: 40px;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
}

.yourlogo :first-child {
  color: red;
  margin: 0 10px 0 20px;
}

.inner {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: example 0.4s;
}

.center {
  width: 500px;
}

.welcome {
  height: 50px;
  font-size: 26px;
  text-align: center;
  transition: opacity 200ms;
  opacity: 1;
}

.welcome p {
  font-size: 14px;
}

.welcome.welcomed {
  opacity: 0;
}

.instructions {
  padding-top: 15px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  color: #555;
  align-self: flex-end;
}

.instructions:hover {
  text-decoration: underline;
}

.instructions.hide {
  transition: opacity 0.2s;
  opacity: 0;
}

.row {
  height: 80px;
  margin: 20px 0;
}

.method {
  display: flex;
  height: 80px;
  width: 500px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 0;
  top: 0px;
  left: 0px;
  transition: box-shadow 0.3s;
}

.method:not(.active):hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.method.active {
  height: 300px;
  width: 520px;
  z-index: 1;
  top: 0px;
  left: -10px;
  transition: top 0.2s, left 0.2s, height 0.2s, width 0.2s;
}

.method.active.tall {
  height: 400px;
}

/* .email.active {
  top: -10px;
}

.twofa.active {
  top: -110px;
} */

/* .bankid.active {
  top: -210px;
} */

@keyframes example {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.method.active > * {
  animation: example 0.4s;
}

.method .name {
  font-size: 22px;
  font-weight: 500;
  flex-grow: 1;
  margin-left: 20px;
}

.method .next {
  display: flex;
  text-transform: capitalize;
  background: #ffecb3;
  border-radius: 5px;
  padding: 3px 0px 3px 5px;
}

.method .next span {
  font-weight: var(--font-weight-semi);
  color: #263238;
}

.method .next svg {
  stroke: #263238;
}

.button {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background: #2196f3;
  color: white;
  cursor: pointer;
  font-weight: 500;
  text-transform: capitalize;
  min-width: 90px;
}

a.button {
  color: white !important;
  text-decoration: none;
}

.button:hover {
  background: #1976d2;
}

.button:active {
  background: #0d47a1;
}

.button.green {
  background: #4caf50;
}

.button.again {
  background: #808080;
}

.button.green:hover:not(.again) {
  background: #388e3c;
}

.button.green:active:not(.again) {
  background: #1b5e20;
}

.button.disabled {
  pointer-events: none;
  background: #ddd;
}

.bankidBtn {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 12px 24px;
  border-radius: 6px;
  border: 1px solid rgb(228, 228, 231);
  color: black;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
}

a.bankidBtn {
  color: black !important;
  text-decoration: none;
}

.bankidBtn span {
  font-weight: var(--font-weight-bold);
  margin-left: 6px;
}

.bankidBtn svg {
  margin-right: 5px;
}

.item {
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  flex-grow: 1;
}

.dialog {
  padding: 25px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.dialog .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*
.dialog .header > :last-child {
  align-self: flex-start;
  cursor: pointer;
}
*/
/*
.dialog .header > :last-child svg {
  width: 26px;
  height: 26px;
  stroke-width: 2px;
  stroke: #4a5568;
  border-radius: 999px;
}

.dialog .header > :last-child :hover {
  stroke: white;
  background: #f44336;
}
*/

.dialog .middle {
  flex-grow: 1;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.bankidMiddle {
  align-items: center;
}

.dialog .footer {
  display: flex;
  justify-content: flex-end;
}

.dialog .optionAndSubmit {
  justify-content: space-between;
}

.dialog .button {
  margin-left: 10px;
}

.dialog .button:not(.bankidBtn) svg {
  width: 20px;
  height: 20px;
  stroke: white;
}

.dialog .button span {
  line-height: 20px;
  font-weight: inherit;
  margin: 0 3px 0 5px;
}

.dialog .input {
  display: block;
  background: #edf2f7;
  padding: 20px;
  width: 100%;
  border-radius: 8px;
  outline: 0;
  appearance: none;
  color: #1a202c;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.dialog .input.error {
  box-shadow: 0 0 0 3px #ef9a9a;
}

.dialog .input::placeholder {
  color: #1a202c99;
  text-transform: capitalize;
}

.columns {
  display: flex;
  flex-grow: 1;
}

.column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.column:first-child:not(:last-child) {
  transform: translate(-5px, 20px);
}

.column:last-child:not(:first-child) {
  transform: translate(5px, 20px);
}

.notice {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-size: 13px;
  font-size: 12px;
}

.prompt {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-size: 18px;
  font-size: 17px;
  font-size: 14px;
}

.column:first-child:last-child .notice {
  font-size: 17px;
}

.column:first-child:last-child .prompt {
  font-size: 20px;
}

.inputPhone {
  position: relative;
}

.inputPhone input {
  padding-left: 60px !important;
}

.inputPhone input + * {
  content: "";
  position: absolute;
  top: 12px;
  left: 12px;
  width: 40px;
  height: 36px;
  background: white;
  border-radius: 8px;
  z-index: 1000000;
  line-height: 36px;
  text-align: center;
}

.pin {
  display: inline-block;
  text-align: center;
}

.pin input {
  width: 32px;
  height: 38px;
  margin: 0 10px;
  font-size: 24px;
  text-align: center;
  border: 2px solid #e2e8f0;
  border-radius: 5px;
  background: #e2e8f0;
  text-transform: uppercase;
}

.pin input:nth-child(3) {
  position: relative;
}

.pin input:focus {
  outline: none;
  border-color: #2196f3;
}

.pin input:not(:last-child):focus + .pin input {
  border-color: #2196f3;
}

.pin span.separator {
  display: inline-block;
  width: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 5px;
  margin: 0 10px;
}

.pin input.invalid {
  border-color: red;
}

.mobilePinMiddle {
  align-items: center;
}

.mobilePin {
  padding: 10px;
  border: 2px solid #e2e8f0;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  box-shadow: none;
  outline: none;
  /* max-width: fit-content; */
  width: 100%;
  text-align: center;
}

.mobilePin:focus {
  border-color: #2196f3;
}

.mobilePin.invalid:focus {
  border-color: red;
}

.hubContainer {
  animation: example 0.4s;
  align-items: center;
}

.hubMobile {
  align-items: flex-start;
}

.hubContainer,
.hubMobile {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
  height: 100%;
}

.hub {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.hub .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 24px 20px 30px 20px;
  margin: 16px;
  cursor: pointer;
}

.hubInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  max-width: 40em;
  line-height: 1.5;
  font-size: 16px;
}

.hub a {
  text-decoration: none;
  color: #2696f3;
  font-weight: var(--font-weight-bold);
}

.hubInfoMobile {
  font-size: 14px;
  text-align: center;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #d8e6fe;
  color: #6299f9;
  font-size: 30px;
  text-align: center;
  line-height: 80px;
  font-weight: var(--font-weight-bold);
  margin-bottom: 24px;
}

.org {
  text-transform: capitalize;
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
  font-size: 16px;
  margin-bottom: 5px;
}

.card > span {
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: var(--font-weight-semi);
}

.card > div {
  font-weight: var(--font-weight-semi);
}

.card > div:not(.org, .esignatureNotice, .absolute) {
  margin-top: 16px;
}

.card div:last-child span {
  font-weight: var(--font-weight-bold);
}

.card.loading {
  opacity: 0.5;
}

.login {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(222, 222, 222, 0.9);
  margin-top: 30px;
  border-radius: 8px;
  padding: 8px 12px;
  width: 200px;
  text-transform: capitalize;
}

.login span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
}

.loginText {
  margin: 0px 0 20px 0;
  text-align: center;
}

.idProviders {
  width: 520px;
  padding: 10px;
  background: white;
  border-radius: 10px;
  height: 60px;
  margin-top: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  left: -10px;
}

.idProviders > :first-of-type svg {
  margin-right: 10px;
}

.idProviders select {
  font-weight: var(--font-weight-bold);
  background: #fff;
  width: 100%;
  cursor: pointer;
}

.idProvidersMobile {
  width: 420px;
}

.providerEmail:hover,
.providerEmail:active {
  box-shadow: 0 0 0 4px rgba(41, 98, 255, 0.2);
}

.providerTwoFa:hover,
.providerTwoFa:active {
  box-shadow: 0 0 0 4px rgba(0, 200, 83, 0.2);
}

.providerBankId:hover,
.providerBankId:active {
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.2);
}

.providerSso:hover,
.providerSso:active {
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.2);
}

.noProviderMobile {
  margin-top: 20px;
}

.noProviderMobile .welcome {
  font-size: 22px;
  margin-bottom: 10px;
  height: fit-content;
}

.undoContainer {
  position: fixed;
  top: 5px;
  right: 20px;
  z-index: 9999;
  margin: 5px 0px;
  transform: translateX(0);
  animation: example 0.4s;
}

.undo {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2196f3;
  border-radius: 999px;
  padding: 12px;
  cursor: pointer;
  color: #fff;
  margin: 10px 0px;
}

.undo.disabled {
  pointer-events: none;
  cursor: none;
  background: #ddd;
}

.undo:hover,
.undo:active {
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.2);
}

.identityContainer {
  position: fixed;
  top: 5px;
  left: 20px;
  z-index: 9999;
  margin: 5px 0px;
  transform: translateX(0);
  animation: example 0.4s;
}

.identity {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 12px;
  margin: 10px 0px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.identity span:nth-child(1) {
  color: #6299f9;
}

.identity div:nth-child(2) {
  margin-left: 5px;
  color: #6299f9;
  font-weight: var(--font-weight-bold);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.identityMobile {
  max-width: 75%;
}

.bankidDirect {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bankidDirectContainer {
  position: fixed;
  top: 16px;
  right: 80px;
  z-index: 9999;
  margin: 5px 0px;
  transform: translateX(0);
}

.bankidDirectMobile {
  position: fixed;
  top: 8px;
  right: 80px;
  z-index: 9999;
}

.dialog .error {
  margin-left: 20px;
}

.dialog .error span {
  display: flex;
  line-height: 2;
}

.dialog .error > span:first-child {
  color: red;
  font-weight: var(--font-weight-bold);
}

.dialog .error > span:nth-child(2) > span {
  font-weight: var(--font-weight-bold);
  margin: 0 4px;
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
}

.option span {
  color: #666666;
  font-weight: var(--font-weight-bold);
}

.option span:hover {
  color: #999999;
  text-decoration: underline;
}

.dropDownButton {
  display: flex;
  align-items: center;
  min-width: 150px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  color: #404854;
}

.dropDownButton [icon="shield"] {
  color: #404854;
}

.dropDownButton span:last-child {
  font-weight: var(--font-weight-bold);
  margin-left: 6px;
}

.dropDownButton:hover {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.05);
}

.menuWrapper {
  position: relative;
}

.menu {
  margin-top: 8px;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 10px 0px;
  cursor: pointer;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu li {
  display: flex;
  width: 150px;
  padding: 10px 0px;
}

.menu li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.menu li > div {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.menu li > div span {
  margin-left: 10px;
  color: #404854;
  font-weight: var(--font-weight-bold);
}

.spinner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(222, 222, 222, 0.9);
  margin-top: 30px;
  border-radius: 8px;
  padding: 10px 12px;
  width: 200px;
}

.waiting {
  cursor: none;
  pointer-events: none;
}

.mobileAuth {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.warning {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
}

.warning p {
  color: red;
  font-size: 14px;
  text-align: center;
}

.retry {
  color: red;
}

.absolute {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 1;
}

.relative {
  position: relative;
}

.esignatureNotice {
  background-color: #ffff00;
  border-radius: 50%;
  padding: 4px;
}

.esignIcon {
  stroke: #1e293b;
}
