.outer {
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  max-height: 50%;
  min-height: 200px;
  /*height: 150px;*/
}

.landscape {
  min-height: 150px;
}

.bar {
  padding: 10px 10px 5px 10px;
  position: relative;
}

.box {
  flex-grow: 1;
  overflow: auto;
  padding: 15px;
}
.bar [data-icon^="header-"] {
  transform: translate(0, 2px);
}

.button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #2196f3 !important;
  background: #e8f0fe !important;
  padding-right: 12px !important;
  border-radius: 8px;
  border-radius: 999px;
}
.button:hover {
  background: #d0e0fc !important;
}
.button:active {
  background: #b9d1fb !important;
}
.button :global .bp3-button-text {
  color: white !important;
  color: #1967d2 !important;
  margin-right: 7px;
  font-weight: var(--font-weight-semi);
}

.button > span:not(:global(.bp3-button-text)) {
  display: flex;
  transform: translate(0, 1px);
  font-size: 11px;
}

.button * {
  color: #bbdefb !important;
  color: #2e7be6 !important;
}
.button svg {
  height: 12px;
}

.RichEditor_editor {
  cursor: text;
  font-size: 16px;
}

.RichEditor_editor .RichEditor_blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor_editor :global .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor_hidePlaceholder :global .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor_controls {
  display: inline-block;
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
  background: #edf2f7;
  background: #f3f4f6;
  border-radius: 999px;
  margin-right: 20px;
}

.RichEditor_styleButton {
  width: 22px;
  height: 22px;
  text-align: center;
  color: #6a7281;
  color: #9da3af;
  color: #848b98;
  cursor: pointer;
  margin: 4px 8px;
  padding: 2px 0;
  display: inline-block;
  border-radius: 999px;
}

.RichEditor_styleButton:first-child {
  margin-left: 4px;
}

.RichEditor_styleButton:last-child {
  margin-right: 4px;
}

.RichEditor_styleButton:hover {
  background: #d1d5db;
  background: #e5e7eb;
  box-shadow: 0 0 0 3px #e5e7eb;
}

.RichEditor_activeButton {
  color: white;
  background: #2196f3 !important;
  box-shadow: 0 0 0 3px #2196f3 !important;
}

.nonepremitted {
  align: center;
  text-align: center;
  background: whitesmoke;
}
