.outer {
  position: relative;
}

.placeholder {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  /*margin: 6px;*/
  height: 58px;
  width: 300px;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 0px #ffffff00;
  box-shadow: 0px 0px 0px 0px #ffffff00;
  display: none;
}

.placeholder.around {
  box-shadow: 0px 0px 0px 3px #2196f3;
  box-shadow: 0px 0px 0px 3px #e2e8f0;
  box-shadow: 0px 0px 0px 3px red;
  /*transition: box-shadow 0.2s ease;*/
  transition: box-shadow 150ms ease 100ms;
}

.droppable {
  border-radius: 7px;
}

.droppable.hover {
  background: white;
  box-shadow: 0px 0px 0px 3px white, 0px 0px 0px 6px #2196f3;
  /*transition: box-shadow ease 0ms 200ms;*/
}

.active {
  pointer-events: none;
}

.appear {
}

.appearActive {
}

.appearDone {
}

.enter,
.enterDone {
  will-change: opacity, height;
}

.enter {
  opacity: 0;
  height: 0;
}

.enterActive {
  opacity: 1;
  height: 58px;
  transition: height ease-out 125ms, opacity ease-in 125ms 125ms;
}

.exit {
  height: 58px;
  opacity: 1;
}

.exitActive {
  opacity: 0;
  height: 0;
  transition: height ease-out 150ms 100ms, opacity ease-in 200ms;
}

.exitDone {
}

.draggable {
  will-change: transform;
}
