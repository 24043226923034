.outer {
  height: 100%;
  border-radius: 10px;
  overflow: scroll;
}

.table {
  width: calc(100% - 10px);
}

/*
.table > :last-child {
  overflow-y: scroll !important;
}
*/

.row,
.headers {
  display: flex;
  color: #262626;
}

.cell,
.header {
  display: flex;
  align-items: center;
  height: 40px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding: 0 12px;
  overflow: hidden;
}

.headers {
  box-shadow: inset 0 -1px 0 #e5e7eb;
  background: #f9fafb;
  letter-spacing: 1px;
  font-size: 15px;
  text-transform: uppercase;
}

.row:last-child {
  /*box-shadow: inset 0 -1px 0 #e5e7eb;*/
}

.row:hover {
  background: #2196f3 !important;
}

.row:hover * {
  color: white !important;
}

.row:nth-child(odd) {
  background: #f9fafb;
}

.row {
  cursor: pointer;
}

.header {
  text-transform: capitalize;
}

.cell {
  border-right: solid 1px #f0f0f0;
}

.cell .item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icon {
  flex-basis: 42px;
  flex-grow: 0;
  flex-shrink: 0;
}

.parties {
  flex-grow: 0;
  flex-shrink: 0;
}

.node {
  flex-grow: 3;
}

.zone {
  flex-grow: 2;
}

.cell.node {
  font-weight: var(--font-weight-semi);
}

.cell.zone .item {
  font-size: 14px;
  color: #212121;
  letter-spacing: 1px;
}

.cell.parties {
}

.cell.parties .signers {
  display: flex;
  flex-direction: row;
}

.cell.parties .signer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  margin: 0 3px;
  border-radius: 999px;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
  background: #ff9800;
  color: white;
}

.cell.parties .signer.approved {
  background: #8bc34a;
}

.cell.parties .signer.rejected {
  background: #f44336;
}

.cell.parties .signer.more {
  background: white;
  color: black;
}

.row:not(:hover) .cell.parties .signer.more {
  box-shadow: 0 0 0 1px #aaa inset;
}

.row:hover .cell.parties .signer {
  background: white;
  color: #2196f3 !important;
}

.cell.status {
  font-size: 11px;
  color: #424242;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
}

.cell.date {
  font-size: 12px;
  color: #424242;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
}

.row:nth-child(odd) .cell {
  border-right: solid 1px #eee;
}

.row:hover .cell {
  border-right: solid 1px transparent;
}

.row .cell:last-child,
.header:last-child {
  border-right: 0;
}

.header {
  border-right: solid 1px #e5e7eb;
}

.header:last-child > * {
  padding-right: 50px;
}
