.spinner {
  height: 44px;
  width: 44px;
  --ani-dur-spin: 1100ms;
  --ani-dur-check: 800ms;
  --check-width: 3px;
  --color: #5cb85c;
  --color-bg: #e2e8f0;
}

.white {
  --color: white;
  --color-bg: transparent;
}

.blue {
  --color: #2196f3;
}

.small {
  --check-width: 2px;
}

.fast {
  --ani-dur-spin: 550ms;
}

.spinner::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border: 2px solid var(--color-bg);
  border-left-color: var(--color);
  animation: spinner var(--ani-dur-spin) infinite linear;
  border-radius: 50%;
  margin-bottom: 20px;
  margin-bottom: -45%;
}

.checked::before {
  border-color: var(--color);
  transition: border 500ms ease-out;
}

.checked::after {
  content: "";
  display: block;
  height: 20px;
  height: 45%;
  width: 10px;
  width: 23%;
  margin-left: 12px;
  margin-left: 27%;
  transform-origin: left top;
  border-right: var(--check-width) solid var(--color);
  border-top: var(--check-width) solid var(--color);
  animation: checked var(--ani-dur-check);
  transform: scaleX(-1) rotate(135deg);
}

/*
.blue.spinner::before {
  border-left-color: #2196f3;
}
.blue.checked::before {
  border-color: #2196f3;
}
.blue.checked::after {
  border-right: 3px solid #2196f3;
  border-top: 3px solid #2196f3;
}
*/

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checked {
  0% {
    height: 0;
    width: 0;
  }
  20% {
    height: 0;
    width: 10px;
    width: 23%;
  }
  40% {
    height: 20px;
    height: 45%;
    width: 10px;
    width: 23%;
  }
  100% {
    height: 20px;
    height: 45%;
    width: 10px;
    width: 23%;
  }
}
