/*  DOCX removing corners */

.docx-header div:nth-child(-n + 2),
.docx-footer div:nth-child(-n + 2) {
  display: none !important;
}

/* DOCX zooming towards center */
.docx-outline {
  transform-origin: 50% 0px !important;
}

/* SLIDES */

#docxjs-container,
.docxjs-page {
  background: #f5f5f5 !important;
}

.docxjs-content {
  border: solid 1px #e0e0e0;
  /*box-shadow: 0 0 0 1px #e0e0e0;*/
}
