.header {
  flex-grow: 1;
}

.header p {
  margin: 10px;
  font-size: 16px;
  font-weight: bold;
}

.box {
  height: calc(100% - 50px);
  overflow: scroll;
}

.spacer {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 20px;
}

.channel-section {
  margin-top: 10px;
}

.section-header {
  font-size: 20px;
  padding: 30px 0 15px 30px;
}

.panel-header {
  padding: 0 0 10px 5px;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
}

.switch-wrapper {
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.switch-wrapper.large {
  height: 50px;
}

.switch-wrapper.enabled.expand::after {
  content: "";
  display: block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #bbb;
  margin: 4px 4px 0 auto;
}

.switch-wrapper :global .bp3-large {
  font-size: 18px;
}

.switch-wrapper :global .bp3-tag {
  padding: 2px 6px;
  line-height: 16px;
  font-size: 12px;
  transform: translate(10px, -1px);
}

.switch-wrapper :global .bp3-switch input:checked ~ .bp3-control-indicator {
  background-color: #66bb6a !important;
}

.switch-wrapper :global .bp3-control-indicator {
  outline: 0 !important;
}

.time-panel {
  padding-left: 10px;
}

.time-panel :global .bp3-timepicker-input-row {
  box-shadow: none !important;
  border: solid 1px #ccc;
}

.time-panel :global .bp3-timepicker-input {
  box-shadow: none !important;
}

.time-panel > * {
  display: flex;
  flex-wrap: wrap;
}

.time-panel > * > :first-child {
  width: 100%;
  padding: 7px 0;
}

.time-panel > * > :nth-child(1) {
}

.time-panel > * > :nth-child(2) {
  margin-right: 10px;
}

.time-panel > * > :nth-child(4) {
  margin: 0 10px;
}

.time-panel > * > :nth-child(n + 1) {
  align-self: center;
}

.switch-panel {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
}

.switch-panel > * {
  width: calc(50% - 5px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  display: flex;
  line-height: 20px;
}

.switch-panel > :nth-child(odd) {
  margin-right: 10px;
}

.method-panel {
  padding-left: 5px;
}

.method-panel :global .bp3-text-muted {
  font-size: 12px;
}

.method-panel :global .bp3-form-group {
  margin: 0;
}

.method-panel :global .bp3-input-group input {
  height: 40px;
  border-radius: 5px;
  box-shadow: none;
}

.method-panel.input-error :global .bp3-input-group input {
  box-shadow: 0 0 0 3px #ef9a9a;
}

.method-panel :global .bp3-input-group button {
  height: 34px;
  background: #66bb6a;
  color: white !important;
}

.method-panel :global .bp3-input-group button:hover {
  background: #4caf50 !important;
}

.method-panel :global .bp3-input-group button:active {
  background: #43a047 !important;
}

.filter-panel {
  padding: 0 15px;
  border-radius: 15px;
  background: #f5f5f5;
}

.filter-panel[border] {
  border-color: #ddd;
}

.filter-panel :global .bp3-control {
  margin: 0;
}

.unblock {
  padding: 10px;
  padding-bottom: 50px;
}

.unblock :global .bp3-dialog-body {
  margin: 0;
  display: flex;
  justify-content: center;
}
