.bar {
  height: 50px;
  display: flex;
  padding: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
}

.bar > :first-child {
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
  color: #4a5568;
  font-weight: var(--font-weight-fat);
}

.bar > :last-child {
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
