.outer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.head {
  padding: 12px;
  display: grid;
  grid-template-columns: auto 1fr 1fr;
}

.head > * + * {
  margin-left: 1rem;
}

.search {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  height: 40px;
  border-radius: 999px;
  background: #d1d5db;
  background: #e2e8f0;
  color: #374151;
  overflow: hidden;
}

.search input {
  width: 100%;
  padding: 0 10px;
  background: transparent;
}

.search > :first-child {
  margin-left: 13px;
  pointer-events: none;
}

.search > :last-child {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 999px;
  cursor: pointer;
}

.search > :last-child:hover {
  background: #d1d5db;
}

.bullet {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 999px;
  background: #d1d5db;
  background: #e2e8f0;
  color: #374151;
  cursor: pointer;
}

.bullet:hover {
  background: #d1d5db;
}

.list {
  flex-grow: 1;
  overflow: hidden;
}

.scroll {
  height: calc(100% - 12px);
  padding: 12px;
  padding-right: 6px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.empty {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.empty > * {
  transform: translate(0, -50px);
}

.empty > svg {
  margin-bottom: 30px;
}

.empty p {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  margin-bottom: 30px;
}

.margin
/*:not(:last-child)*/ {
  /*padding-bottom: 8px;*/
  padding: 4px 0;
}

.section {
  margin: 5px 0;
}

.section > :first-child {
  display: flex;
  height: 30px;
  padding: 0 10px;
  border-radius: 999px;
  align-items: center;
  cursor: pointer;
}

.section > :first-child:hover {
  background: #ddd;
  background: #fff;
  background: #e2e8f0;
}

.section > :first-child > :first-child {
  color: white;
  color: #1a202c;
}

.section > :first-child > :last-child {
  color: #1a202c;
}

/*.section > :first-child > :not(:first-child):not(:last-child) {*/

.section > :first-child > :nth-child(2) {
  flex-grow: 1;
  line-height: 30px;
  margin-left: 10px;
  text-transform: capitalize;
  text-transform: uppercase;
  font-size: 13px;
  color: #fff;
  color: #1a202c;
  font-weight: var(--font-weight-bold);
}

.section > :first-child > :nth-last-child(2) {
  border-radius: 999px;
  font-size: 10px;
  font-weight: bold;
  margin-right: 10px;
}

.section > :first-child [data-icon="caret-left"] {
  transition: transform 200ms;
}

.section.open > :first-child [data-icon="caret-left"] {
  transform: rotate(-90deg);
}

.zone {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

[data-dragging] .zone {
  width: max-content;
  background: #2196f3;
  color: white;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.zone:hover {
  background: #e2e8f0;
}

.current {
  background: #2196f3;
  color: white;
}

.current:hover {
  background: #2196f3;
}

.avatar {
  flex-shrink: 0;
  --size: 36px;
  margin: 7px;
  border-radius: 999px;
  width: var(--size);
  height: var(--size);
  line-height: var(--size);
  text-align: center;
  background: #2196f3;
  color: white;
  font-weight: var(--font-weight-fat);
  text-transform: uppercase;
  font-size: 16px;
}

.badge {
  position: absolute;
  background-color: #ff6e40;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 11px;
  left: 33px;
}

.template .avatar {
  background: #222222;
  background: #212121;
  background: #263238;
}

.current .avatar {
  background: white;
  color: #2196f3;
}

.label {
  flex-grow: 1;
  font-weight: var(--font-weight-semi);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bold {
  font-weight: 600;
}

.icons {
  flex-shrink: 0;
  margin: 10px;
  color: #4a5568;
}

.icons > * {
  display: inline-block;
  vertical-align: text-bottom;
  background: #e2e8f0;
  overflow: hidden;
  border: solid 3px #e2e8f0;
}

.icons > :first-child {
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
}

.icons > :last-child {
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
}

.icons svg {
  max-height: 14px;
  max-width: 14px;
  margin: 1px;
}

svg.users {
  margin: 0;
  stroke-width: 2px;
  max-height: 16px;
  max-width: 16px;
}

.emptyButton {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.375rem;
  text-align: center;
  font-weight: normal;
  background: #3b82f6;
  color: white;
}

.emptyButton:hover {
  background: #1d4ed8;
}
