.dialog {
  padding: 20px 16px 16px;
}

.dialog :global(.bp3-dialog-header) {
  padding-left: 15px;
}

.dialog .verticalSpace {
  height: 17px;
}

.title {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.title > * + * {
  margin-left: 15px;
}

.quickIcon {
  display: flex;
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  background: #2979ff;
}

.quickIcon :global .bp3-icon {
  margin-right: 0;
  color: white;
}

.dialogTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dialogBody {
  /*px-3 pt-8 pb-3 space-y-10*/
  padding: 0.75rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
}

/*
.dialogBody > * + * {
  margin-top: 2.5rem;
}
*/

.dialogInput {
  background: #e5e7eb;
  background: #edf2f7;
  display: block;
  padding: 1.25rem;
  width: 100%;
  border-radius: 0.5rem;
  appearance: none;
  line-height: 1.5;
  color: #1f2937;
  font-weight: 400;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.dialogInput:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.dialogInput::placeholder {
  color: #4b5563;
}

.dialogButton {
  align-self: flex-end;
  user-select: none;
  padding: 1rem;
  border-radius: 0.375rem;
  float: right;
  background: #3b82f6;
  color: #f3f4f6;
  font-weight: 600;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}

.dialogButton:hover {
  background: #2563eb;
}

.dialogButton:active {
  background: #1d4ed8;
}

.dialogButton.success {
  background: #22c55e;
}

.dialogButton.success:hover {
  background: #16a34a;
}

.dialogButton.success:active {
  background: #15803d;
}

.dialogButton.danger {
  background: #ef4444;
}

.dialogButton.danger:hover {
  background: #dc2626;
}

.dialogButton.danger:active {
  background: #b91c1c;
}

.dialogButton.neutral {
  background: #cbd5e1;
  color: #1e293b;
}

.dialogButton.neutral:hover {
  background: #b0bccd;
}

.dialogButton.neutral:active {
  background: #94a3b8;
}

.dialogButton:disabled {
  background: #dddddd;
}

.dialogButton.full {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dialogButton.full span {
  font-weight: 600;
}

.errorMessage {
  color: red;
  visibility: hidden;
}

.errorMessage.show {
  visibility: visible;
}

.errorInput {
  box-shadow: 0 0 0 4px #ff5252;
}

.select {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.select select {
  /*font-weight: var(--font-weight-bold);*/
}

.moreOptions {
  /*margin-top: 20px;*/
  align-self: flex-end;
  flex: 0 1 auto;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  user-select: none;
  border-radius: 999px;
  background: #e8f0fe !important;
  color: #1967d2 !important;
  font-weight: var(--font-weight-semi);
  cursor: pointer;
}

.moreOptions > :first-child {
  margin-right: 10px;
  vertical-align: -2px;
}

.moreOptions:hover {
  background: #d0e0fc !important;
}

.moreOptions:active {
  background: #b9d1fb !important;
}

.label {
  font-size: 14px;
  padding: 0 0 5px 8px;
  /*margin-top: 20px;*/
}

.select {
  /*margin-top: 10px;*/
}

.checkbox {
  flex: 0 1;
  align-self: flex-start;
  overflow: hidden;
  border-radius: 999px;
  background: #ffecb3;
  font-size: 14px;
  height: 30px;
  margin-left: 5px;
  margin-right: 20px;
  padding-right: 30px;
  padding-left: 10px;
}

.checkbox:hover {
  background: #ffe082;
}

.checkbox:active {
  background: #ffd54f;
}

.checkbox > span {
  height: 30px;
  line-height: 30px;
  color: #ff6f00;
}

.checkbox > :nth-last-child(n + 3) {
  font-size: 11px;
  margin-right: 7px;
  color: #f57f17;
  font-weight: var(--font-weight-bold);
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
}

.checkbox > :nth-last-child(3) {
  font-weight: var(--font-weight-semi);
  color: #e65100;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /*text-transform: capitalize;*/
}

.checkbox :global .checkmark {
  width: 22px;
  height: 22px;
  top: 4px;
  right: 6px;
  left: auto;
  border-radius: 8px;
  background: white !important;
}

.checkbox :global .checkmark:after {
  top: 2px;
  left: 6px;
  border-color: #ef6c00;
}

.checkboxes {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  font-size: 20px;
}

.checkboxes > * {
  flex-basis: 50%;
}

.checkboxes span {
  font-size: 18px;
}

.checkboxes > :nth-child(n + 3) {
  margin-top: 15px;
}

:global .checkbox input ~ .checkmark:after {
  display: block;
  transform: rotate(40deg) scale(0);
  transition: transform 200ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
}

:global .checkbox input:checked ~ .checkmark:after {
  transform: rotate(40deg) scale(1);
}

.br {
  flex-basis: 100%;
}

.pill {
  display: flex;
  flex: 0 1 auto;
  align-self: flex-start;
  overflow: hidden;
  border-radius: 999px;
  background: #ffecb3;
  font-size: 14px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
}

.pill > * {
  color: #ff6f00;
}

.pill > :nth-last-child(n + 2) {
  padding-left: 10px;
  font-size: 11px;
  color: #f57f17;
  font-weight: var(--font-weight-bold);
  letter-spacing: 1px;
  text-transform: uppercase;
}

.pill > :last-child {
  font-weight: var(--font-weight-semi);
  padding: 0 10px;
  /*background: #FFE082;*/
  color: #e65100;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.yeah {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.yeah > :first-child,
.yeah > :last-child {
  flex-shrink: 0;
  white-space: nowrap;
  font-size: 20px;
  font-weight: var(--font-weight-semi);
}

.usher {
  font-size: 16px;
  margin: 0 10px;
}

.usher [data-icon] {
  margin: 0 10px;
  transform: translate(0, -1px);
}

.usher + .usher {
  margin-top: 30px;
}

.usher b {
  font-weight: var(--font-weight-semi);
}

.usher:first-of-type [data-icon] {
  color: #66bb6a;
  color: #2979ff;
}

.usher:first-of-type + .usher [data-icon] {
  color: #f44336;
  color: #2979ff;
}

.cool {
  display: flex;
  font-size: 15px;
  text-align: center;
  justify-content: center;
}

.cool > :first-child,
.cool > :last-child {
  margin: 0 10px;
}

.cool :global .bp3-switch {
  pointer-events: none;
  transform: translate(0, 1px);
}

.cool :global .bp3-switch {
  margin: 0 !important;
  padding: 0 !important;
}

.cool :global .bp3-control-indicator {
  margin-left: 0 !important;
  margin-right: 0 !important;
  outline: 0 !important;
}

.cool :global .bp3-switch input:checked ~ .bp3-control-indicator {
  background-color: #66bb6a !important;
}

.cool :global .bp3-switch input:checked:disabled ~ .bp3-control-indicator {
  background-color: #c8e6c9 !important;
}

.pinContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewBody {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 20px 0;
}

.previewBody section > h4 {
  margin-bottom: 10px;
}

.previewBody section > p {
  line-height: 1.5;
}

.previewBody > div:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
}

.previewBody aside {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.previewBody aside > :first-child {
  margin-right: 5px;
}

.buttonGroup {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
}

.buttonGroup > :last-child {
  margin-left: 10px;
}

.mobile {
  flex-direction: column;
  width: 100%;
}

.mobile > * {
  width: 100%;
  margin-left: 0;
}

.mobile > :first-child {
  padding: 10px;
  margin-bottom: 10px;
}

.mobile > :last-child {
  margin: 0;
  padding: 10px;
}

.tag {
  margin-left: 10px;
}

.previewBody p > span {
  font-weight: var(--font-weight-bold);
}

.buttonText {
  margin-right: 22px;
}
