.banner {
  height: 70px;
  margin: 15px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.banner .badge {
  width: 50px;
  padding: 10px;
  flex-shrink: 0;
}

.banner .badge svg {
  height: 30px;
  width: 30px;
}

.banner .text {
  height: 42px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.banner .text :first-child {
  font-weight: var(--font-weight-fat);
}

.banner .text :last-child {
  font-size: 13px;
}

.banner .flip {
  flex-shrink: 0;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
}

.banner .button {
  padding: 10px;
  height: 40px;
  margin: 10px;
  margin-right: 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
}

.banner .cancel {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner .cancel svg {
  width: 16px;
  height: 16px;
}

.banner.esignature {
  background: #1e293b;
  /*background: linear-gradient(60deg, rgba(49,55,28,1) 0%, rgba(30,41,59,1) 30%, rgba(30,41,59,1) 100%);*/
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.banner.esignature:hover {
  background: #0f172a;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.banner.esignature .badge svg {
  stroke: yellow;
}

.banner.esignature .text {
  color: yellow;
}

.banner.esignature .button {
  background: #0f172a;
  background: #070b15;
  color: #fef08a;
  font-weight: var(--font-weight-bold);
}

.banner.esignature:hover .button {
  background: #334155;
}

.banner.esignature:hover .cancel:hover + .button {
  background: #0f172a;
}

.banner.esignature .cancel:hover {
  background: #334155;
}

.banner.esignature .cancel svg {
  stroke: #fef08a;
}

.banner.expire {
  background: #3d5afe;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.banner.expire:hover {
  background: #304ffe;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.banner.expire .badge svg {
  stroke: #ffffff;
}

.banner.expire .text {
  color: #ffffff;
}

.banner.expire .button {
  background: #1d4ed8;
  color: #ffffff;
  font-weight: var(--font-weight-bold);
}

.banner.expire:hover .button {
  background: #536dfe;
}

.banner.expire:hover .cancel:hover + .button {
  background: #304ffe;
}

.banner.expire .cancel:hover {
  background: #536dfe;
}

.banner.expire .cancel svg {
  stroke: #ffffff;
}

/*****/

.dialogListRow {
  cursor: pointer;
  margin-bottom: 40px;
  display: flex;
  border-radius: 10px;
  padding: 0 10px;
}

.dialogListRow:hover {
  background: #eee;
}

.dialogListRow:active {
  background: #ddd;
}

.dialogListRowMain {
}

.dialogListRowMain {
  overflow: hidden;
}

.dialogListRowEnd {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 70px;
}

.dialogListRowEnd [data-icon] {
  width: 26px;
  height: 26px;
}

.dialogListRowEnd [data-icon="tick"] {
  color: green;
}

.dialogListRowEnd [data-icon="time"] {
  color: red;
}

.dialogListRowSub {
  display: flex;
}

.dialogListRowSub > :nth-child(odd) {
  flex-shrink: -0;
}

.dialogListRowSub > * {
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialogListRowSub:first-child {
  font-weight: var(--font-weight-bold);
  font-size: 16px;
  line-height: 16px;
  padding: 10px 0;
}

.dialogListRowSub:first-child > * {
  font-weight: var(--font-weight-bold);
}

.dialogListRowSub:first-child [data-icon] {
  width: 16px;
  height: 16px;
}

.dialogListRowSub:first-child > :nth-child(odd) {
  margin-right: 5px;
}

.dialogListRowSub:first-child > :nth-child(even):not(:last-child) {
  margin-right: 15px;
}

.dialogListRowSub:last-child {
  font-size: 13px;
  line-height: 13px;
  padding: 10px 0;
}

.dialogListRowSub:last-child [data-icon] {
  width: 13px;
  height: 13px;
}

.dialogListRowSub:last-child > :nth-child(odd) {
  margin-right: 3px;
}

.dialogListRowSub:last-child > :nth-child(even):not(:last-child) {
  margin-right: 10px;
}

/*
.dialogListRow :first-child {
  font-weight: bold;
}

.dialogListRow :nth-child(2) {
  font-style: italic;
}

.dialogListRow:hover {
  text-decoration: underline;
}
*/

.bar {
  display: flex;
  height: 48px;
  color: #4a5568;
  justify-content: space-between;
}

.header {
  height: 36px;
  padding: 10px 10px;
  position: relative;
  font-weight: var(--font-weight-fat);
  flex-basis: 50%;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header:last-child {
  text-align: end;
}

.header:hover {
  border-radius: 6px;
  background: #edf2f7;
  cursor: pointer;
}
