.outer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bar {
  height: 48px;
  display: flex;
  padding: 6px;
  color: #4a5568;
}

.header {
  display: flex;
  height: 37px;
  padding: 10px 10px 10px 10px;
  position: relative;
  flex-grow: 5;
  font-weight: var(--font-weight-fat);
}

.header:not(.no_hover):hover {
  border-radius: 6px;
  background: #edf2f7;
  cursor: pointer;
}

.header:first-child {
  flex-grow: 10;
}

.header:last-child {
  flex-grow: 0;
}

.header > :first-child {
  font-weight: inherit;
  flex-grow: 1;
}

.header > :not(:first-child):not(:last-child) {
  background: #ffecb3;
  color: #ff6f00;
  border-radius: 999px;
  padding: 2px 5px;
  font-size: 11px;
  margin-right: 15px;
  text-transform: capitalize;
}

.rest {
  flex-grow: 1;
  padding: 16px;
}

.nodeResult {
  color: gray;
}

.tooltip {
  position: relative;
  /* display: inline-block; */
}

/* Tooltip text */

.tooltip .tooltiptext {
  visibility: hidden;
  max-width: 420px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  /*border-radius: 6px;*/
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptext {
  width: 420px;
  top: 100%;
  left: 50%;
  margin-left: -210px;
  /* Use half of the width (420/2 = 20), to center the tooltip */
}

/* Show the tooltip text when you mouse over the tooltip container */

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.search {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  height: 2.5rem;
  border-radius: 999px;
  background: #d1d5db;
  background: #e2e8f0;
  color: #374151;
  overflow: hidden;
}

.search input {
  background: transparent;
  margin-left: 10px;
}

.search svg {
  display: block;
  margin-left: 13px;
  pointer-events: none;
}

/* Rows and items */
.row {
  position: relative;
  z-index: 2;
  height: 50px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 0 10px;
}

.row:hover {
  background: #edf2f7;
}

.item {
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-shrink: 1;
}

.item:first-child {
  flex-shrink: 1;
}

.item:last-child {
  flex-shrink: 0;
  font-size: 12px;
  color: #555;
}

.item:first-child > :first-child {
  flex-shrink: 0;
  margin-right: 5px;
}

.item:first-child > :last-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
