/*--- SIGNATURE ---*/

/***** BANKID *****/
.sign {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  align-items: center;
  z-index: 1;
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px;
  height: 40px;
  text-transform: uppercase;
  width: fit-content;
  padding: 0 10px;
  color: #10161a;
  background-color: #ffc940;
}

.sign:disabled {
  cursor: not-allowed;
  background-color: #dce0e5;
  color: rgba(92, 112, 128, 0.6);
}

/*
.unsigned {
  display: grid;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  width: 420px;
  border-radius: 3px;
  padding: 10px;
  background-color: #ffffff;
  color: #10161a;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
*/

.esignatureIcon {
  width: 30px;
  height: 30px;
}

.unsignedWrapper {
  display: grid;
}

@media only screen and (max-width: 450px) {
  .unsigned {
    width: 300px;
  }
}

.header {
  display: grid;
  grid-template-columns: auto min-content;
  align-items: center;
  margin: 10px 0px;
  text-transform: uppercase;
  padding: 5px;
}

.header button {
  cursor: pointer;
  margin-right: 10px;
}

.header img {
  height: 24px;
  width: auto;
}

.signerInfo {
  display: grid;
  align-content: space-evenly;
  border-radius: 8px;
  padding: 10px;
  height: 120px;
}

.signerInfo span {
  margin-left: 5px;
}

.signerInfo div {
  font-weight: var(--font-weight-bold);
}

.signerName {
  text-transform: capitalize;
}

.documentTitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.checkboxDisclaimer {
  margin: 10px 0 10px 0;
  height: fit-content;
  padding: 5px;
}

.signButton {
  display: grid;
  align-items: center;
  grid-template-columns: [first] 90px [second] 1fr [third] 90px;
  background-color: #ffc940;
  border-radius: 3px;
  height: 65px;
  width: 100%;
}

.signButton:disabled {
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6);
  background-color: #dce0e5;
}

.signButton span {
  font-weight: var(--font-weight-bold);
}

.signButton svg {
  height: 36px;
  width: auto;
  margin-left: 10px;
}

.bankidSpinner {
  margin-left: 10px;
}

.cancelButton {
  display: grid;
  align-items: center;
  justify-items: center;
  height: 60px;
}

/***** STATUS TAG *****/

.signed {
  display: inline-grid;
  grid-template-columns: auto 1fr repeat(3, auto);
  gap: 8px;
  align-items: center;
  color: #0d8050;
  background-color: rgba(15, 153, 96, 0.25);
  padding: 0 16px 0 16px;
}

.partialSigned {
  padding: 0 16px;
  color: #557c55;
  background-color: rgba(166, 207, 152, 0.25);
}

.canceled {
  color: #c23030;
  background-color: rgba(219, 55, 55, 0.15);
}

.queue {
  color: #bf7326;
  background-color: rgba(217, 130, 43, 0.15);
}

.queue {
  display: inline-grid;
  grid-template-columns: repeat(6, auto);
  gap: 8px;
  align-items: center;
  padding: 0 16px;
}

.spinner {
  display: inline-grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  padding: 0 16px;
}

.canceled,
.partialSigned {
  display: inline-grid;
  grid-template-columns: repeat(5, auto);
  gap: 8px;
  align-items: center;
  padding: 0 16px;
}

.queue,
.signed,
.partialSigned,
.canceled,
.spinner {
  z-index: 1;
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px;
  height: 40px;
  text-transform: uppercase;
  width: fit-content;
}

.queue span,
.signed span,
.partialSigned span,
.canceled span,
.spinner span {
  font-weight: var(--font-weight-bold);
}

/* @media screen and (max-width: 900px) {
  .queue,
  .signed,
  .partialSigned,
  .canceled,
  .spinner {
    top: 120px;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
  }
} */

/***** QUEUE *****/
.queueDigit {
  color: #10161a;
  text-decoration: underline;
}

.queueSigners {
  color: #10161a;
}

/***** INFO *****/
.info {
  cursor: pointer;
}

.alert {
  background: #ebf1f5;
}

.alert p {
  font-weight: var(--font-weight-semi);
}

.alert svg {
  color: #0d8050;
}

/***** VERIFICATION *****/

.verification {
  display: grid;
  grid-template-columns: repeat(5, auto);
}

.verificationButton {
  display: grid;
  padding: 0px 16px;
  align-items: center;
  height: 34px;
  margin-right: 10px;
  color: #0d8050;
  background-color: rgba(15, 153, 96, 0.25);
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: --var(--font-weight-bold);
  cursor: pointer;
}

.verificationButton span {
  font-weight: 500;
}

/***** SPINNER *****/
.spinner {
  color: #a67908;
  background-color: rgba(255, 201, 64, 0.25);
}

.pulse {
  margin-right: 10px;
  animation: pulse 3s infinite linear;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
