.sidekick {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sidekick .verticalSpace {
  height: 17px;
}

.header {
  width: 100%;
  padding: 10px 15px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
}

.header [data-k-icon-sign] {
  width: 30px;
  height: 30px;
  stroke: #475569;
}

.header [icon="cross"] {
  padding: 10px;
  cursor: pointer;
}

.header [icon="endorsed"] {
  color: #2b3051;
  padding: 5px;
  align-self: center;
}

.button {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background: #2196f3;
  color: white;
  cursor: pointer;
  font-weight: 500;
}

.button:first-letter {
  text-transform: capitalize;
}

.button.pill {
  border-radius: 999px;
}

.button span {
  font-weight: var(--font-weight-bold);
}

.button > * + * {
  margin-left: 10px;
}

a.button {
  color: white !important;
  text-decoration: none;
}

.button [data-k-icon-sign] {
  width: 20px;
  height: 20px;
  margin-right: -5px;
  stroke: black;
}

.button:hover {
  background: #1976d2;
}

.button:active {
  background: #0d47a1;
}

.button.green {
  background: #4caf50;
}

.button.green:hover {
  background: #388e3c;
}

.button.green:active {
  background: #1b5e20;
}

.button.green [data-k-icon-sign] {
  stroke: white;
  stroke-width: 2;
}

.button.red {
  background: #f44336;
}

.button.red:hover {
  background: #d32f2f;
}

.button.red:active {
  background: #b71c1c;
}

.button.yellow {
  background: #ffeb3b;
  background: #ffc107;
}

.button.yellow:hover {
  background: #fbc02d;
  background: #ffa000;
}

.button.yellow:active {
  background: #f57f17;
  background: #ff6f00;
}

.button.yellow {
  color: black;
}

.button.cyan {
  background: #7bdcb5;
  color: #2b3051;
}

.button.cyan:hover {
  background: #00d084;
}

.button:disabled {
  pointer-events: none;
  background: #ddd;
}

.block {
  width: 250px;
}

.block.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block .label {
  font-weight: var(--font-weight-bold);
  margin-bottom: 10px;
}

.block .label.ready {
  margin-left: 15px;
  margin-bottom: 20px;
}

.block.queue .label.names {
  margin-left: 15px;
  margin-bottom: 0px;
}

.columns {
  display: flex;
}

.columns.sign .button {
  margin: 0 10px;
}

.queue .row {
  height: 50px;
  display: flex;
}

.queue .name {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.queue .row > :first-child::before {
  z-index: 1;
  display: block;
  content: "";
  background: white;
  margin: 8px;
}

.queue .list.ordered .row:nth-child(n + 2) > :first-child::after {
  order: -1;
  z-index: 0;
  width: 0;
  height: 40px;
  margin-right: -5px;
  display: block;
  content: "";
  border-right: dotted 3px #ddd;
  transform: translate(23px, -25px);
}

.queue .list.ordered .row.done > :first-child::after {
  border: solid 2px #00c853;
}

.queue .list.ordered .row.done.rejected > :first-child::after {
  border: solid 2px #d32f2f;
}

.completed {
  display: flex;
  align-items: center;
  margin: 15px 0 0 15px;
}

.completed [icon="tick-circle"] {
  color: #00c853;
  margin-right: 10px;
}

.row [icon="ban-circle"] {
  color: #d32f2f;
  margin-right: 10px;
  z-index: 1;
}

.row [icon="user"] {
  color: #ddd;
  background: #fff;
  margin-right: 10px;
  z-index: 1;
}

.row [icon="tick-circle"] {
  color: #00c853;
  margin-right: 10px;
  z-index: 1;
}

.info {
  height: 260;
  padding: 16px;
}

.info div:first-child {
  color: #2196f3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info div:nth-child(even) {
  color: #abb3bf;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info div:nth-child(odd) {
  font-weight: var(--font-weight-semi);
  overflow: hidden;
  text-overflow: ellipsis;
}

.info div:nth-last-child(2) {
  color: #ff9800;
}

.status {
  border-radius: 999px;
  border: solid 1px #ddd;
  color: #222;
  padding: 10px;
}

.status [data-icon] {
  margin-right: 10px;
}

.status.green {
  background: #ebfff3;
  border-color: #00c853;
}

.status [icon="endorsed"] {
  color: #00c853;
}

.status.red {
  background: #ffebee;
  border-color: #ffcdd2;
}

.status.red svg {
  color: #ef5350;
}

.status.orange {
  background: #fff3e0;
  border-color: #ffe0b2;
}

.status.orange svg {
  color: #ffa726;
}

.status.cyan {
  background: #d9f9e8;
  border-color: #8fd8b8;
}

.status.cyan svg {
  color: #6ec7a5;
  filter: brightness(80%);
}

.dropdown {
  display: block;
  align-items: center;
  max-width: 498px;
  margin-bottom: 8px;
  border-radius: 3px;
  cursor: pointer;
  background: white;
  transition: height 200ms ease-out;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  /* IF WE WANT BOX SHADOW BORDER INSTEAD UN-COMMENT CSS-RULE BELOW */
  /* box-shadow: rgba(16, 22, 26, 0.1) 0px 0px 0px 1px,
    rgba(16, 22, 26, 0) 0px 0px 0px, rgba(16, 22, 26, 0.2) 0px 1px 1px; */
}

.dropdown > :first-child {
  display: flex;
  padding: 0 20px;
  height: 50px;
  line-height: 58px;
  align-items: center;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown > :first-child > :nth-child(2) {
  font-weight: var(--font-weight-bold);
  margin-left: 10px;
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown > :first-child > :nth-child(3) {
  transition: transform 200ms;
}

.dropdownOpen > :first-child > :nth-child(3) {
  transform: rotate(180deg);
}

.dropdown > :nth-child(n + 2):empty {
  opacity: 0;
  transition: none;
}

.dropdown > :nth-child(n + 2) {
  opacity: 1;
  transition: opacity 333ms ease-out 200ms;
}

.icon[icon="user"] {
  color: #ddd;
}

.icon[icon="user"].selected {
  color: #2b95d6;
}

.icon[icon="tick-circle"] {
  color: #00c853;
}

.icon[icon="ban-circle"] {
  color: #d32f2f;
}

.icon[icon="endorsed"] {
  color: #00d084;
}

.reject {
  color: #d32f2f;
}

.approve {
  color: #00c853;
}

@media only screen and (max-width: 600px) {
  .block {
    max-width: 200px;
  }
}
