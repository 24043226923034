.info {
  text-align: center;
  font-weight: 100;
  color: #263238;
  /*padding: 10px 0;*/
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.largeButton {
  height: 60px;
  line-height: 60px;
  /*margin: 20px 0;*/
  border-radius: 8px;
  background: #edf2f7;
  cursor: pointer;
  opacity: 1;
  display: flex;
  align-items: center;
}

.largeButton [data-icon="walk"] {
  margin: 0 13px 1px 15px;
}

.largeButton [data-icon="caret-down"] {
  margin: 0 20px 1px 15px;
}

.largeButton > :nth-last-child(2) {
  flex-grow: 1;
  font-weight: var(--font-weight-bold);
}

.largeButton [data-icon="caret-down"] {
}

.largeButton.opened {
  height: 20px;
  padding: 0 10px;
  background: transparent !important;
  color: #182026 !important;
  /*pointer-events: none;*/
}

.largeButton.opened > :nth-last-child(2) {
  flex-grow: 1;
  font-weight: var(--font-weight-normal);
}

.largeButton.opened:hover > :last-child {
  background: #ddd;
  border-radius: 999px;
}

.smallButton {
  width: calc(50% - 5px);
  height: 58px;
  line-height: 58px;
  margin-top: 10px;
  padding: 0 15px;
  border-radius: 8px;
  background: #edf2f7;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.smallButton > :first-child {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.smallButton > :last-child {
  flex-shrink: 0;
  background: red;
  display: inline-block;
  height: 22px;
  width: 22px;
  line-height: 22px;
  border-radius: 999px;
  background: #00000026;
  color: #0000007f;
  font-weight: bold;
}

.smallButton > :last-child:hover {
  background: #0000003f;
}

.list {
  padding: 10px;
  font-weight: 100;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 10px;
}

.list > * {
  width: calc(50% - 10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list > * > :first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list [data-icon] {
}

.list [data-icon$="tick"] {
  color: #4caf50;
}

.list [data-icon$="cross"] {
  color: #f44336;
}
