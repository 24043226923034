.dialog {
  padding: 20px 16px 16px;
}

.dialog :global(.bp3-dialog-header) {
  padding-left: 15px;
}

.title {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.title > * + * {
  margin-left: 15px;
}

.quickIcon {
  display: flex;
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  background: #2979ff;
}

.quickIcon :global .bp3-icon {
  margin-right: 0;
  color: white;
}

.dialogTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dialogBody {
  padding: 0.75rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
}

.dialogInput {
  background: #edf2f7;
  display: block;
  padding: 1.25rem;
  width: 100%;
  border-radius: 0.5rem;
  appearance: none;
  line-height: 1.5;
  color: #1f2937;
  font-weight: 400;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.dialogInput:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.dialogInput::placeholder {
  color: #4b5563;
}

.dialogButtons {
  display: flex;
  justify-content: flex-end;
}

.dialogButton + .dialogButton {
  margin-left: 25px;
}

.dialogButtons > :first-child {
  font-weight: var(--font-weight-semi);
  background: #e5e7eb;
  color: #374151;
}

.dialogButtons > :first-child:hover {
  background: #d1d5db;
}

.dialogButtons > :first-child:active {
  background: #9da3af;
}

.dialogButton {
  align-self: flex-end;
  user-select: none;
  padding: 1rem;
  border-radius: 0.375rem;
  background: #3b82f6;
  color: #f3f4f6;
  font-weight: var(--font-weight-bold);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}
.dialogButton:hover {
  background: #2563eb;
}
.dialogButton:active {
  background: #1d4ed8;
}

.dialogButton.success {
  background: #22c55e;
}
.dialogButton.success:hover {
  background: #16a34a;
}
.dialogButton.success:active {
  background: #15803d;
}

.dialogButton.danger {
  background: #ef4444;
}
.dialogButton.danger:hover {
  background: #dc2626;
}
.dialogButton.danger:active {
  background: #b91c1c;
}

.dialogButton.neutral {
  background: #cbd5e1;
  color: #1e293b;
}
.dialogButton.neutral:hover {
  background: #b0bccd;
}
.dialogButton.neutral:active {
  background: #94a3b8;
}

.dialogButton:disabled {
  background: #dddddd;
  cursor: not-allowed;
}

.dialogButton.full {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dialogButton.full span {
  font-weight: 600;
}

.dialogButton[disabled] {
  color: #999;
}

.dialogButton.small {
  padding: 0.5rem;
}

.dialogButton.right {
  float: right;
}

.errorMessage {
  color: red;
  visibility: hidden;
}

.errorMessage.show {
  visibility: visible;
}

.errorInput {
  box-shadow: 0 0 0 4px #ff5252;
}

.select {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.select select {
}

.input {
  background: #edf2f7;
  display: block;
  padding: 1.25rem;
  width: 100%;
  border-radius: 0.5rem;
  appearance: none;
  line-height: 1.5;
  color: #1f2937;
  font-weight: 400;
}

.input::placeholder {
  color: #1f293799;
  font-style: italic;
}

.input.error {
  box-shadow: 0 0 0 3px #ef9a9a;
}

.inputGroup {
  display: flex;
}

.inputGroup > * {
  height: 60px;
}

.inputGroup > input {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.inputGroup > button {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}

.inputGroup > :first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.inputGroup > :last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pill {
  height: 30px;
  max-width: 100%;
  display: inline-flex;
  border-radius: 999px;
  background: #ffecb3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}

.pill > * {
  border-radius: 999px;
  color: #ff6f00;
}

.pill > :nth-last-child(n + 2) {
  padding-left: 10px;
  font-size: 11px;
  color: #f57f17;
  font-weight: var(--font-weight-bold);
  letter-spacing: 1px;
  text-transform: uppercase;
}

.pill > :last-child {
  font-weight: var(--font-weight-semi);
  padding: 0 10px;
  color: #e65100;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.results {
}

.result {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 8px;
}

.result:hover {
  background: #edf2f7;
}

.result.active {
  background: #2196f3;
}

.column {
  flex-grow: 5;
  flex-basis: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.result > * + * {
  margin-left: 10px;
}

.column:first-child {
  flex-grow: 10;
}

.recipient {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: capitalize;
  border-radius: 999px;
  height: 28px;
  padding: 0px 8px;
  white-space: nowrap;
  font-size: 11px;
  overflow: hidden;
  background: #eceff1;
  color: #555;
}

.recipient > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.recipient :nth-child(2) {
  font-size: 10px;
  color: #757575;
}

.recipient :first-child:last-child {
  font-size: 12px;
}

.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: capitalize;
  height: 40px;
}

.user > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user > * + * {
  margin-top: 3px;
}

.user :last-child {
  font-size: 11px;
  color: #757575;
}

.user :first-child {
  font-size: 12px;
  color: #212121;
  font-weight: var(--font-weight-semi);
}

.user :first-child:last-child {
  font-size: 13px;
}

.result.active .user span {
  color: white;
}

.provider {
  height: 28px;
  display: flex;
}
.provider span {
  display: block;
  height: 28px;
  line-height: 28px;
  padding: 0 8px;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 999px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #e2e8f0;
  color: #334155;
}

.provider-bankid span,
.provider-2fa span {
  background: #69f0ae;
  color: #1b5e20;
}

.role {
  height: 28px;
  display: flex;
  justify-content: flex-end;
}
.role span {
  display: block;
  height: 28px;
  line-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: 999px;
  background: #eeeeee;
  color: #424242;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.result.active .provider span,
.result.active .role span {
  background: white !important;
  color: #2196f3 !important;
}

.empty {
  display: flex;
  width: 75%;
  flex-direction: column;
  margin: 0 auto;
  padding: 50px 20px;
  color: #263238;
  text-align: center;
  border-radius: 999px;
}

.empty > :first-child {
  color: #ff5252;
}

.empty > :last-child {
  color: #757575;
  font-size: 13px;
}

.empty > :last-child > :first-child {
  color: #212121;
  padding: 15px 0 10px 0;
  font-size: 19px;
  font-weight: var(--font-weight-bold);
}

.info {
  display: flex;
  margin: 20px auto 0 auto;
  color: #263238;
  border-radius: 999px;
  padding: 10px;
}

.info > :first-child {
  color: #ff5252;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 0 10px;
}

.info > :last-child {
  color: #757575;
  font-size: 13px;
}

.info > :last-child > :first-child {
  color: #424242;
  padding: 0 0 5px 0;
  font-size: 16px;
  font-weight: var(--font-weight-bold);
}

.hero {
  font-size: 20px;
  font-weight: var(--font-weight-semi);
  color: #263238;
  text-align: center;
  padding: 20px 0 40px 0;
}

.row {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.row > * {
  height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.row > * + * {
  margin-left: 10px;
}

.row.wow {
  justify-content: center;
}

.row.wow > :global(.bp3-icon) {
  height: auto;
  color: #455a64;
}

.head {
  line-height: 27px;
  font-weight: var(--font-weight-semi);
  font-size: 16px;
}

.icon {
  text-align: center;
  color: #2979ff;
  padding: 20px 0;
}
