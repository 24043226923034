.heightAnimation {
  transition: height 200ms;
  overflow: hidden;
  /*min-height: 600px;*/
}

.outer {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  padding-top: 2rem;
}

.dialog {
  padding: 20px 16px 16px;
}

.dialog .verticalSpace {
  height: 17px;
}

.dialog :global(.bp3-dialog-header) {
  padding-left: 15px;
}

/*
.dialog :global(.bp3-heading) > :first-child {
  display: inline-block;
  text-align: top;
  line-height: 20px;
}
*/

.title {
  display: flex;
  align-items: center;
}

.title > * + * {
  margin-left: 15px;
}

.select {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.select select {
  font-weight: var(--font-weight-bold);
}

.select.error {
  box-shadow: 0 0 0 3px #ef9a9a;
}

.input {
  background: #e5e7eb;
  background: #edf2f7;
  display: block;
  padding: 1.25rem;
  width: 100%;
  border-radius: 0.5rem;
  appearance: none;
  line-height: 1.5;
  color: #1f2937;
  font-weight: 400;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

div.input {
  overflow: hidden;
  word-break: break-all;
  display: flex;
  align-items: center;
}

textarea.input {
  resize: none;
  overflow: hidden;
}

.input::placeholder {
  color: #1f293799;
}

.input.error {
  box-shadow: 0 0 0 3px #ef9a9a;
}

.inputPhone {
  position: relative;
}

.inputPhone input {
  padding-left: 60px;
}

.inputPhone input + * {
  content: "";
  position: absolute;
  top: 12px;
  left: 12px;
  width: 40px;
  height: 36px;
  background: white;
  border-radius: 8px;
  z-index: 1000000;
  line-height: 36px;
  text-align: center;
}

.label {
  font-size: 14px;
  padding: 0 0 5px 8px;
}

.dialogInput:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.dialogInput::placeholder {
  color: #4b5563;
}

.dialogButtons {
  display: flex;
  justify-content: flex-end;
}

.dialogButton + .dialogButton {
  margin-left: 25px;
}

.dialogButtons > :first-child {
  font-weight: var(--font-weight-semi);
  background: #e5e7eb;
  color: #374151;
}

.dialogButtons > :first-child:hover {
  background: #d1d5db;
}

.dialogButtons > :first-child:active {
  background: #9da3af;
}

.dialogButton {
  align-self: flex-end;
  user-select: none;
  padding: 1rem;
  border-radius: 0.375rem;
  background: #3b82f6;
  color: #f3f4f6;
  font-weight: 600;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}

.dialogButton:hover {
  background: #2563eb;
}

.dialogButton:active {
  background: #1d4ed8;
}

.dialogButton:disabled {
  background: #dddddd;
}

.inputGroup {
  display: flex;
}

.inputGroup > * {
  height: 60px;
}

.inputGroup > input {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.inputGroup > button {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}

.inputGroup > :first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.inputGroup > :last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.moreOptions {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  user-select: none;
  border-radius: 999px;
  background: #e8f0fe !important;
  color: #1967d2 !important;
  font-weight: var(--font-weight-semi);
  cursor: pointer;
}

.moreOptions > :first-child {
  margin-right: 10px;
  vertical-align: -2px;
}

.moreOptions:hover {
  background: #d0e0fc !important;
}

.moreOptions:active {
  background: #b9d1fb !important;
}

.midgard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.midgard > * {
  flex: 1 0 100%;
}

.moreOptions ~ *:not(.checkbox) {
  order: 4;
}

.moreOptions {
  order: 3;
  flex: 0 0 auto;
}

.checkbox {
  order: 2;
  flex: 1 1;
  max-width: fit-content;
  overflow: hidden;
  border-radius: 999px;
  background: #ffecb3;
  font-size: 14px;
  height: 30px;
  margin-right: 20px;
  padding-right: 40px;
  padding-left: 10px;
}

.checkbox:hover {
  background: #ffe082;
}

.checkbox:active {
  background: #ffd54f;
}

.checkbox > span {
  height: 30px;
  line-height: 30px;
  color: #ff6f00;
}

.checkbox > :nth-last-child(n + 4) {
  font-size: 11px;
  margin-right: 7px;
  color: #f57f17;
  font-weight: var(--font-weight-bold);
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
}

.checkbox > :nth-last-child(3) {
  font-weight: var(--font-weight-semi);
  color: #e65100;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /*text-transform: capitalize;*/
}

.checkbox :global .checkmark {
  width: 22px;
  height: 22px;
  top: 4px;
  right: 6px;
  left: auto;
  border-radius: 8px;
  background: white !important;
}

.checkbox :global .checkmark:after {
  top: 2px;
  left: 6px;
  border-color: #ef6c00;
}

:global .checkbox input ~ .checkmark:after {
  display: block;
  transform: rotate(40deg) scale(0);
  transition: transform 200ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
}

:global .checkbox input:checked ~ .checkmark:after {
  transform: rotate(40deg) scale(1);
}

.br {
  flex-basis: 100%;
}

.sso {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  color: #ff7f00;
}
