.button {
  user-select: none;
  cursor: pointer;
  padding: 16px 20px;
  border-radius: 6px;
  float: right;
  background: #2196f3;
  color: #fafafa;
  font-weight: var(--font-weight-bold);
  /*box-shadow: 0 0 15px -5px #00000040;*/

  /* first bezier params controls speed*/
  transition: background-position 10s cubic-bezier(0.1, 1, 0, 1);
  background-size: 200% 100%;
}

.primary {
  background-image: linear-gradient(to right, #2196f3 50%, #64b5f6 50%);
}

.checkbox {
  display: none;
}

.checkbox:not(:checked) + .primary:hover {
  background: #1976d2;
}

.checkbox:not(:checked) + .primary:active {
  background: #0d47a1;
}

.checkbox:checked + .button {
  pointer-events: none;
  background-position: -100%;
  box-shadow: 0 0 15px -5px #00000040;
}
