:root {
  --font-weight-normal: 300;
  --font-weight-semi: 400;
  --font-weight-bold: 500;
  --font-weight-fat: 600;
  --app-border-size: 6px;
  --app-border-top-size: 5px;
  --app-border-bottom-size: 16px;
  --app-border-color: #c5e1a5;
  --app-border-radius: 18px;
  --app-background-color: #eef2f5;
  --workspace-name-color: #212121;
  --workspace-lock-color: #4caf50;
  --accent-color: #2196f3;
  --accent-color-dark: #1e88e5;
  --tile-border-radius: 10px;
  --tile-border-radius-top: 10px;
  --tile-margin-top: 20px;
  --tile-margin: 20px;
  --tile-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.15);
  --tile-background: white;
}

:root.inside-iframe {
  --app-border-color: red;
  --app-border-size: 0px;
  --app-border-top-size: 0px;
  --app-border-bottom-size: 0px;
  --app-border-radius: 0px;
}

:root.inside-iframe #root > :first-child > :nth-child(2) {
  display: none;
}

@media only screen and (max-width: 600px) {
  :root {
    /*--app-border-size: 5px;*/
    /*--app-border-bottom-size: 14px;*/
    --app-border-radius: 18px;
    --app-border-size: 5px;
    /*--app-border-bottom-size: 5px;*/
    --tile-border-radius-top: 5px;
    --tile-border-radius: 20px;
    --tile-margin-top: 3px;
    --tile-margin: 0px;
  }
}

@media only screen and (max-width: 850px) {
  .bp3-overlay {
    overflow: hidden !important;
  }

  .bp3-dialog {
    height: calc(var(--vh, 1vh) * 100) !important;
    width: 100vw !important;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bp3-dialog > * {
    width: 90vw !important;
  }
}

html,
body {
  height: calc(var(--vh, 1vh) * 100);
  transition: height 250ms;
}

body {
  margin: 0;
  user-select: none;
  overflow: hidden;
  touch-action: manipulation;
  background: white;
  background: #24292e;
  background: #9da3af;
  background: #d1d5db;
  background: #6b7280;
  background: #f1f8e9;
  background: red;
  background: #c5e1a5;
}

#root {
  width: 100%;
  height: 100%;
  position: relative;
  /*
  border-radius: 20px;
  overflow: hidden;
  */

  background: #5ec524;
  background: linear-gradient(to right, #a8ff78, #78ffd6);
  background: linear-gradient(to right, #a1ffce, #faffd1);
  background: linear-gradient(to left, #dad299, #b0dab9);
  background: linear-gradient(-45deg, #dad299, #b0dab9);
  /*animation: gradient 5s linear infinite;*/
  /*background-size: 200% 200%;*/
  background: #c5e1a5;
  background: #c8e6c9;
  background: linear-gradient(-45deg, #dad299, #b0dab9);
  background: #c5e1a5;
}

#root:empty {
  background: white;
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

* {
  box-sizing: border-box;
}

:not(.docx-container) * {
  font-family: "Inter", sans-serif;
  font-weight: var(--font-weight-normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #e0e0e0 white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 20px;
  border: 3px solid white;
}

.sentry-error {
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sentry-error h1 {
  font-size: 22px;
}

.sentry-error > * + * {
  margin-top: 30px;
}

.sentry-error > a {
  color: blue;
}

/*
body:not(.no-tree-row-ani) .tree-row-ani-enter {
  opacity: 0.01;
  height: 0;
}

body:not(.no-tree-row-ani) .tree-row-ani-enter.tree-row-ani-enter-active {
  opacity: 1;
  height: 50px;
  transition: all 200ms;
}

body:not(.no-tree-row-ani) .tree-row-ani-exit {
  opacity: 1;
  height: 50px;
}

body:not(.no-tree-row-ani) .tree-row-ani-exit.tree-row-ani-exit-active {
  opacity: 0.01;
  height: 0;
  transition: all 200ms;
}
*/

.select select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 1.25rem;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
}

.select select::-ms-expand {
  display: none;
}

.select option {
  font-size: 1.2rem;
}

.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  min-width: 15ch;

  cursor: pointer;
  background: #edf2f7;
  width: 100%;
  border-radius: 0.5rem;
  line-height: 1.5;
  color: #1f2937;
  font-weight: 400;
}

.select select,
.select::after {
  grid-area: select;
}

.select:not(.select--multiple)::after {
  content: "";
  justify-self: end;
  width: 0.8em;
  height: 0.5em;
  margin-right: 20px;
  background-color: #1f2937;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

.select select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}

.select select[multiple] {
  padding: 0.5rem;
  /*
   * Safari will not reveal an option
   * unless the select height has room to 
   * show all of it
   * Firefox and Chrome allow showing 
   * a partial option
   */
  height: 6rem;
  /* 
   * Experimental - styling of selected options
   * in the multiselect
   * Not supported crossbrowser
   */
}

.select select[multiple] option {
  white-space: normal;
  outline-color: var(--select-focus);
}

.select--disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}

.select + label {
  margin-top: 2rem;
  font-size: 1.125rem;
  font-weight: 500;
}

/* The container */
.checkbox {
  display: flex;
  height: 25px;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 13px;
  user-select: none;
  font-weight: var(--font-weight-normal);
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #e5e7eb;
  border-radius: 10px;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  background-color: #d1d5db;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: #4caf50;
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 8px;
  top: 4px;
  width: 9px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(40deg);
}

.checkbox input:disabled ~ * {
  opacity: 0.5;
  cursor: not-allowed;
}

.checkbox:hover input:disabled ~ .checkmark {
  background-color: #e5e7eb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.k-dialog-top-right {
  align-self: flex-start;
  margin: 20px 20px 0 auto;
  border-radius: 12px !important;
}

/*
.simplebar-scrollbar::before {
  width: 5px;
  background-color: #263238;
}
*/

.bp3-portal {
  z-index: 1;
}

.bp3-button.bp3-large {
  border-radius: 10px !important;
}

.bp3-overlay-backdrop:not(.bp3-popover-backdrop) {
  /*background: #eceff1aa !important;*/
  backdrop-filter: blur(1px);
  background: linear-gradient(to right, #8360c3aa, #2ebf91aa);
  background: linear-gradient(to right, #0f202755, #203a4355, #2c536455);
  background: linear-gradient(to right, #d3cce355, #e9e4f055);
  background: linear-gradient(to left, #d9a7c755, #fffcdc55);
  background: none;
  background: linear-gradient(to bottom right, #3e77af55, #d2bec255);
  background: #1a202c55;
  background: linear-gradient(to top, #cfd9df77 0%, #e2ebf077 100%);
}

.bp3-dialog {
  background: white;
}

:not(.bp3-multistep-dialog) > .bp3-dialog-header {
  box-shadow: none;
  border-radius: 0 !important;
}

.bp3-multistep-dialog-footer {
  padding: 24px;
}

.bp3-dialog {
  box-shadow: 0 5px 100px -5px rgba(0, 0, 0, 0.1),
    0 1px 7px -3px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  overflow: hidden;
}

.bp3-dialog-close-button {
  padding: 2px !important;
  min-width: 34px !important;
  border-radius: 10px !important;
}

.bp3-dialog-close-button:hover {
  background: #eee !important;
}

.bp3-dialog-close-button svg {
  width: 30px;
  height: 30px;
}

.bp3-dialog-close-button svg path {
  stroke: white;
  stroke-width: 1;
  color: #2c3e50;
}

.bp3-dialog-close-button:hover svg path {
  stroke: #eee;
}

.bp3-popover.bp3-minimal {
  box-shadow: 0 3px 25px 0px rgba(0, 0, 0, 0.1),
    0 1px 7px -3px rgba(0, 0, 0, 0.3);
}

.bp3-popover.bp3-minimal,
.bp3-popover.bp3-minimal .bp3-popover-content,
.bp3-popover.bp3-minimal .bp3-menu {
  border-radius: 7px;
}

.bp3-popover.bp3-minimal .bp3-menu {
  padding: 6px;
}

.bp3-popover.bp3-minimal .bp3-menu-item:hover {
  background: #e2e8f0;
  border-radius: 5px !important;
}

.bp3-toast-container

/*.my-toaster*/ {
  /*transform: translate(15px, -15px);*/
  padding: 60px 50px;
}

.bp3-toast-container .bp3-toast {
  padding: 10px 10px;
  border-radius: 5px;
  box-shadow: 0 3px 25px 0px rgba(0, 0, 0, 0.1),
    0 1px 7px -3px rgba(0, 0, 0, 0.3);
}

.bp3-toast-container .bp3-intent-primary {
  background: #2979ff;
}

.bp3-toast-container .bp3-intent-success {
  background: #00c853;
}

.bp3-toast-container .bp3-intent-danger {
  background: #ff3d00;
}

.bp3-toast-container .bp3-button {
  border-radius: 5px;
}

.bp3-toast-container .bp3-toast-message {
  font-weight: var(--font-weight-bold);
}

.bp3-toast-container .k-toast-check > :first-child,
.bp3-toast-container .k-toast-spinner > :first-child {
  width: 20px;
}

.bp3-toast-container .k-toast-check > :first-child {
  margin: 10px 10px 0 15px;
}

.bp3-toast-container .k-toast-spinner .bp3-spinner {
  margin: 10px 10px 0 15px;
  border-radius: 100%;
  background: transparent;
}

.bp3-toast-container .k-toast-spinner .bp3-spinner svg {
  stroke-width: 10px;
}

.bp3-toast-container .k-toast-spinner .bp3-spinner .bp3-spinner-track {
  stroke: transparent;
}

.bp3-toast-container .k-toast-spinner .bp3-spinner .bp3-spinner-head {
  stroke: white;
}

.bp3-popover2-backdrop {
  backdrop-filter: none !important;
  background: transparent !important;
}

.k-role-picker-popover {
}

.bp3-popover2-backdrop ~ * .bp3-input-group {
  display: none !important;
}

.bp3-popover2-backdrop ~ * .bp3-menu {
  width: 350px;
  padding-top: 0 !important;
}

.bp3-popover2-backdrop ~ * .bp3-menu > * {
  margin: 5px 5px 0 5px;
}

.bp3-popover2-backdrop ~ * .bp3-popover2-content {
  padding: 0;
  padding-bottom: 5px;
}

.k-role-picker-gray-panel {
  .bp3-popover-content,
  .bp3-menu {
    background: #f5f5f5;
  }
}

.k-role-picker-gray-arrow {
  .bp3-popover-arrow-fill {
    fill: #f5f5f5;
  }
}

.squircle {
  clip-path: polygon(
    100% 50%,
    100% 53.4%,
    100% 55.3%,
    100% 57%,
    99.9% 58.5%,
    99.9% 59.8%,
    99.8% 61.1%,
    99.8% 62.3%,
    99.7% 63.4%,
    99.6% 64.5%,
    99.5% 65.6%,
    99.4% 66.6%,
    99.3% 67.5%,
    99.1% 68.5%,
    99% 69.4%,
    98.9% 70.3%,
    98.7% 71.2%,
    98.5% 72%,
    98.4% 72.9%,
    98.2% 73.7%,
    98% 74.5%,
    97.8% 75.2%,
    97.5% 76%,
    97.3% 76.7%,
    97.1% 77.4%,
    96.8% 78.2%,
    96.6% 78.9%,
    96.3% 79.5%,
    96% 80.2%,
    95.7% 80.9%,
    95.4% 81.5%,
    95.1% 82.1%,
    94.8% 82.7%,
    94.5% 83.3%,
    94.1% 83.9%,
    93.8% 84.5%,
    93.4% 85.1%,
    93% 85.6%,
    92.7% 86.2%,
    92.3% 86.7%,
    91.9% 87.2%,
    91.4% 87.8%,
    91% 88.3%,
    90.6% 88.7%,
    90.1% 89.2%,
    89.7% 89.7%,
    89.2% 90.1%,
    88.7% 90.6%,
    88.3% 91%,
    87.8% 91.4%,
    87.2% 91.9%,
    86.7% 92.3%,
    86.2% 92.7%,
    85.6% 93%,
    85.1% 93.4%,
    84.5% 93.8%,
    83.9% 94.1%,
    83.3% 94.5%,
    82.7% 94.8%,
    82.1% 95.1%,
    81.5% 95.4%,
    80.9% 95.7%,
    80.2% 96%,
    79.5% 96.3%,
    78.9% 96.6%,
    78.2% 96.8%,
    77.4% 97.1%,
    76.7% 97.3%,
    76% 97.5%,
    75.2% 97.8%,
    74.5% 98%,
    73.7% 98.2%,
    72.9% 98.4%,
    72% 98.5%,
    71.2% 98.7%,
    70.3% 98.9%,
    69.4% 99%,
    68.5% 99.1%,
    67.5% 99.3%,
    66.6% 99.4%,
    65.6% 99.5%,
    64.5% 99.6%,
    63.4% 99.7%,
    62.3% 99.8%,
    61.1% 99.8%,
    59.8% 99.9%,
    58.5% 99.9%,
    57% 100%,
    55.3% 100%,
    53.4% 100%,
    50% 100%,
    46.6% 100%,
    44.7% 100%,
    43% 100%,
    41.5% 99.9%,
    40.2% 99.9%,
    38.9% 99.8%,
    37.7% 99.8%,
    36.6% 99.7%,
    35.5% 99.6%,
    34.4% 99.5%,
    33.4% 99.4%,
    32.5% 99.3%,
    31.5% 99.1%,
    30.6% 99%,
    29.7% 98.9%,
    28.8% 98.7%,
    28% 98.5%,
    27.1% 98.4%,
    26.3% 98.2%,
    25.5% 98%,
    24.8% 97.8%,
    24% 97.5%,
    23.3% 97.3%,
    22.6% 97.1%,
    21.8% 96.8%,
    21.1% 96.6%,
    20.5% 96.3%,
    19.8% 96%,
    19.1% 95.7%,
    18.5% 95.4%,
    17.9% 95.1%,
    17.3% 94.8%,
    16.7% 94.5%,
    16.1% 94.1%,
    15.5% 93.8%,
    14.9% 93.4%,
    14.4% 93%,
    13.8% 92.7%,
    13.3% 92.3%,
    12.8% 91.9%,
    12.2% 91.4%,
    11.7% 91%,
    11.3% 90.6%,
    10.8% 90.1%,
    10.3% 89.7%,
    9.9% 89.2%,
    9.4% 88.7%,
    9% 88.3%,
    8.6% 87.8%,
    8.1% 87.2%,
    7.7% 86.7%,
    7.3% 86.2%,
    7% 85.6%,
    6.6% 85.1%,
    6.2% 84.5%,
    5.9% 83.9%,
    5.5% 83.3%,
    5.2% 82.7%,
    4.9% 82.1%,
    4.6% 81.5%,
    4.3% 80.9%,
    4% 80.2%,
    3.7% 79.5%,
    3.4% 78.9%,
    3.2% 78.2%,
    2.9% 77.4%,
    2.7% 76.7%,
    2.5% 76%,
    2.2% 75.2%,
    2% 74.5%,
    1.8% 73.7%,
    1.6% 72.9%,
    1.5% 72%,
    1.3% 71.2%,
    1.1% 70.3%,
    1% 69.4%,
    0.9% 68.5%,
    0.7% 67.5%,
    0.6% 66.6%,
    0.5% 65.6%,
    0.4% 64.5%,
    0.3% 63.4%,
    0.2% 62.3%,
    0.2% 61.1%,
    0.1% 59.8%,
    0.1% 58.5%,
    0% 57%,
    0% 55.3%,
    0% 53.4%,
    0% 50%,
    0% 46.6%,
    0% 44.7%,
    0% 43%,
    0.1% 41.5%,
    0.1% 40.2%,
    0.2% 38.9%,
    0.2% 37.7%,
    0.3% 36.6%,
    0.4% 35.5%,
    0.5% 34.4%,
    0.6% 33.4%,
    0.7% 32.5%,
    0.9% 31.5%,
    1% 30.6%,
    1.1% 29.7%,
    1.3% 28.8%,
    1.5% 28%,
    1.6% 27.1%,
    1.8% 26.3%,
    2% 25.5%,
    2.2% 24.8%,
    2.5% 24%,
    2.7% 23.3%,
    2.9% 22.6%,
    3.2% 21.8%,
    3.4% 21.1%,
    3.7% 20.5%,
    4% 19.8%,
    4.3% 19.1%,
    4.6% 18.5%,
    4.9% 17.9%,
    5.2% 17.3%,
    5.5% 16.7%,
    5.9% 16.1%,
    6.2% 15.5%,
    6.6% 14.9%,
    7% 14.4%,
    7.3% 13.8%,
    7.7% 13.3%,
    8.1% 12.8%,
    8.6% 12.2%,
    9% 11.7%,
    9.4% 11.3%,
    9.9% 10.8%,
    10.3% 10.3%,
    10.8% 9.9%,
    11.3% 9.4%,
    11.7% 9%,
    12.2% 8.6%,
    12.8% 8.1%,
    13.3% 7.7%,
    13.8% 7.3%,
    14.4% 7%,
    14.9% 6.6%,
    15.5% 6.2%,
    16.1% 5.9%,
    16.7% 5.5%,
    17.3% 5.2%,
    17.9% 4.9%,
    18.5% 4.6%,
    19.1% 4.3%,
    19.8% 4%,
    20.5% 3.7%,
    21.1% 3.4%,
    21.8% 3.2%,
    22.6% 2.9%,
    23.3% 2.7%,
    24% 2.5%,
    24.8% 2.2%,
    25.5% 2%,
    26.3% 1.8%,
    27.1% 1.6%,
    28% 1.5%,
    28.8% 1.3%,
    29.7% 1.1%,
    30.6% 1%,
    31.5% 0.9%,
    32.5% 0.7%,
    33.4% 0.6%,
    34.4% 0.5%,
    35.5% 0.4%,
    36.6% 0.3%,
    37.7% 0.2%,
    38.9% 0.2%,
    40.2% 0.1%,
    41.5% 0.1%,
    43% 0%,
    44.7% 0%,
    46.6% 0%,
    50% 0%,
    53.4% 0%,
    55.3% 0%,
    57% 0%,
    58.5% 0.1%,
    59.8% 0.1%,
    61.1% 0.2%,
    62.3% 0.2%,
    63.4% 0.3%,
    64.5% 0.4%,
    65.6% 0.5%,
    66.6% 0.6%,
    67.5% 0.7%,
    68.5% 0.9%,
    69.4% 1%,
    70.3% 1.1%,
    71.2% 1.3%,
    72% 1.5%,
    72.9% 1.6%,
    73.7% 1.8%,
    74.5% 2%,
    75.2% 2.2%,
    76% 2.5%,
    76.7% 2.7%,
    77.4% 2.9%,
    78.2% 3.2%,
    78.9% 3.4%,
    79.5% 3.7%,
    80.2% 4%,
    80.9% 4.3%,
    81.5% 4.6%,
    82.1% 4.9%,
    82.7% 5.2%,
    83.3% 5.5%,
    83.9% 5.9%,
    84.5% 6.2%,
    85.1% 6.6%,
    85.6% 7%,
    86.2% 7.3%,
    86.7% 7.7%,
    87.2% 8.1%,
    87.8% 8.6%,
    88.3% 9%,
    88.7% 9.4%,
    89.2% 9.9%,
    89.7% 10.3%,
    90.1% 10.8%,
    90.6% 11.3%,
    91% 11.7%,
    91.4% 12.2%,
    91.9% 12.8%,
    92.3% 13.3%,
    92.7% 13.8%,
    93% 14.4%,
    93.4% 14.9%,
    93.8% 15.5%,
    94.1% 16.1%,
    94.5% 16.7%,
    94.8% 17.3%,
    95.1% 17.9%,
    95.4% 18.5%,
    95.7% 19.1%,
    96% 19.8%,
    96.3% 20.5%,
    96.6% 21.1%,
    96.8% 21.8%,
    97.1% 22.6%,
    97.3% 23.3%,
    97.5% 24%,
    97.8% 24.8%,
    98% 25.5%,
    98.2% 26.3%,
    98.4% 27.1%,
    98.5% 28%,
    98.7% 28.8%,
    98.9% 29.7%,
    99% 30.6%,
    99.1% 31.5%,
    99.3% 32.5%,
    99.4% 33.4%,
    99.5% 34.4%,
    99.6% 35.5%,
    99.7% 36.6%,
    99.8% 37.7%,
    99.8% 38.9%,
    99.9% 40.2%,
    99.9% 41.5%,
    100% 43%,
    100% 44.7%,
    100% 46.6%
  );
}
