.overlay:not(:empty) {
  height: 100vh;
  overflow: hidden;
}

.media {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
}

@media only screen and (max-width: 600px) {
  .media {
    justify-content: flex-start;
    padding-top: 20px;
  }
}

.callout {
  max-width: 40rem;
  color: #935610;
  margin: 10px 0px;
}

.callout h4 {
  font-weight: var(--font-weight-bold);
}

.danger {
  color: #c23030;
}

.image {
  max-width: 80%;
  max-height: 80vh;
  height: auto;
}

video {
  max-width: 80%;
  max-height: 80%;
  width: auto;
  height: auto;
  object-fit: contain;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  text-align: center;
  font-size: 1.2em;
  padding: 10px;
}

.icon {
  margin-bottom: 10px;
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error span {
  display: block;
  margin-top: 5px;
}
