.outer {
  height: 100%;
  /*
  display: flex;
  flex-direction: column;
  */
}

.bar {
  display: flex;
  height: 48px;
  padding: 6px;
  color: #4a5568;
}

.header {
  display: flex;
  height: 37px;
  padding: 10px 10px 0 10px;
  position: relative;
  flex-grow: 5;
  font-weight: var(--font-weight-fat);
}

.header:nth-child(n + 2):hover {
  border-radius: 6px;
  background: #edf2f7;
  cursor: pointer;
}

.header:first-child {
  flex-grow: 10;
}
.header:last-child {
  flex-grow: 0;
}
.header > :first-child {
  font-weight: inherit;
  flex-grow: 1;
}

.rest {
  --px: calc((100% - 500px) / 2);
  --pl: max(20px, var(--px));
  --pr: max(8px, var(--px) - 12px);
  height: calc(100% - 55px);
  padding: 20px var(--pr) 20px var(--pl);
  overflow-y: scroll;
}

.card {
  --avatar-size: 8rem;
}

.circle {
  margin: 0 auto;
  border-radius: 50%;
  width: var(--avatar-size);
  height: var(--avatar-size);
  text-align: center;
  background: #e0f7fa;
  background: #e0f2f1;
  background: #eceff1;
}

.initials {
  font-size: calc(var(--avatar-size) / 2);
  line-height: 1;
  position: relative;
  top: calc(var(--avatar-size) / 4);
  text-transform: uppercase;
  color: #00bcd4;
  color: #009688;
  color: #607d8b;
}

.name {
  overflow: hidden;
  padding: 20px;
}

.recipient {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: capitalize;
  color: #222;
  white-space: nowrap;
  font-size: 24px;
  overflow: hidden;
  text-align: center;
}

.recipient > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.recipient :nth-child(2) {
  margin-top: 10px;
  font-size: 24px;
  color: #757575;
}

.recipient :first-child:last-child {
  font-size: 24px;
}

.params {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pill {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 15px;
  border-radius: 999px;
  margin: 10px;
  background: #f3e5f5;
  color: #9c27b0;
  font-size: 14px;
}

.pill > * + * {
  margin-left: 10px;
}

.pill > :first-child {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 11px;
  font-weight: var(--font-weight-fat);
}

.pill > :last-child {
  text-transform: capitalize;
}

.button {
  height: 58px;
  line-height: 58px;
  max-width: 498px;
  margin: 20px auto;
  padding: 0 20px;
  border-radius: 8px;
  cursor: pointer;
  background: #3b82f6;
  background: #e8f0fe;
  color: #f3f4f6;
  color: #1967d2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button:hover {
  background: #2563eb;
  background: #d0e0fc;
}

.button:active {
  background: #cbd5e0;
  background: #1d4ed8;
  background: #b9d1fb;
}

.button > * + * {
  margin-left: 10px;
}

.button > :last-child {
  font-weight: var(--font-weight-bold);
}

.delete {
  background: #ffebee;
  color: #f44336;
}

.delete:hover {
  background: #ffcdd2;
  background: #f44336;
  color: white;
}

.delete:active {
  background: #ef9a9a;
  background: #d50000;
  color: white;
}
