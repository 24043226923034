.outer {
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: 10px;
}

.outer.searching {
  padding-top: 40px;
}

.outer.grouped [data-icon="search"],
.outer.searching [data-icon="search"],
.outer.searching [data-icon="diagram-tree"] {
  display: none;
}

.search.visible {
  visibility: visible;
}

.search {
  display: flex;
  align-items: center;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  height: 40px;
  border-bottom: solid 1px #e5e7eb;
}

.search > * {
  margin-left: 10px;
}

.search input {
  height: 100%;
  flex-grow: 1;
}

.search input::placeholder {
  text-transform: capitalize;
}

.table {
  display: block;
}

.table > :last-child {
  overflow-y: scroll !important;
}

.row,
.headerRow,
.pagination {
  display: flex;
  color: #404040;
}

.row:last-child,
.headerRow:last-child,
.pagination {
  border-bottom: 0;
}

.row:hover {
  background: #2196f3 !important;
}

.row:hover *,
.row:hover ::after {
  color: white !important;
}

.row:hover .pill {
  background: white !important;
  color: #2196f3 !important;
}

.row.odd {
  background: #f9fafb;
}

.row {
  cursor: pointer;
}

.headerRow {
  box-shadow: inset 0 -1px 0 #e5e7eb;
  background: #f9fafb;
  color: #525252;
  letter-spacing: 1px;
  font-size: 13px;
  text-transform: uppercase;
  overflow-y: scroll;
  scrollbar-color: #f9fafb #f9fafb;
}

.headerRow::-webkit-scrollbar-track {
  background: #f9fafb;
}
.headerRow::-webkit-scrollbar-thumb {
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.pagination {
  background: rgba(42, 117, 146, 1);
  color: white;
}

.cell,
.header {
  padding: 12px;
}

.cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  border-right: solid 1px #f0f0f0;
}

.cell > :empty::after {
  content: "NO VALUE";
  font-size: 11px;
  letter-spacing: 1px;
  color: #999;
}

.row.odd .cell {
  border-right: solid 1px #eee;
}

.row:hover .cell {
  border-right: solid 1px transparent;
}

.cell:first-child * {
  font-weight: var(--font-weight-semi);
  color: #262626;
}

.row .cell:last-child,
.header:last-child {
  border-right: 0;
}

.header {
  font-weight: bold;
  border-right: solid 1px #e5e7eb;
}

.header > * {
  display: flex;
}
.header:last-child > * {
  padding-right: 50px;
}

.header > * > :first-child {
  font-weight: var(--font-weight-bold);
  margin-right: 25px;
  margin-right: auto;
}

.button {
  font-size: 1rem;
  padding: 0.5rem 0.7rem;
  background: white;
  border-radius: 5px;
  cursor: pointer;
}

.button:disabled {
  opacity: 0.3;
}

.select {
  appearance: none;
  background: white;
  border: 0;
  margin: 0;
  color: black;
  font-size: 1rem;
  border-radius: 5px;
  padding: 0.5rem 0.7rem;
  border: 0;
  cursor: pointer;
}

.input {
  box-shadow: none !important;
}

.outer [data-icon] {
  margin: 0 5px;
  color: #455a64;
  cursor: pointer;
}

.outer [data-icon]:not([data-icon^="caret"]) {
  opacity: 0.7;
}

.outer [data-icon]:not([data-icon^="caret"]):hover {
  opacity: 1;
}

.outer [data-icon="reset"] {
  color: #c62828;
}

.outer [data-icon="diagram-tree"] {
  color: #304ffe;
}

.outer [data-icon^="caret"] * {
  color: #37474f;
}

.outer [data-icon="search"] {
  color: #455a64;
}

.dialogSteps {
  display: flex;
  flex-direction: column;
}

.pill {
  font-size: 11px;
  border-radius: 999px;
  padding: 2px 5px;
}

.wide {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.skeleton > * {
  display: flex;
  height: 50px;
  padding: 0 20px;
  align-items: center;
}

.skeleton > * > * {
  height: 20px;
  border-radius: 7px;
  width: 25%;
  margin-right: 5%;
  background: #eee;
  background: #00000010;
}

.skeleton > :first-child {
  background: #00000005;
}

.skeleton > :first-child > * {
  width: 10%;
  margin-right: 20%;
  background: #00000010;
}

.skeleton > :last-child > :not(:first-child),
.skeleton > :not(:first-child):not(:last-child) > :first-child {
  width: 20%;
  margin-right: 10%;
}
