.outer {
  height: 100%;
  /*
  display: flex;
  flex-direction: column;
  */
}

.bar {
  height: 48px;
  display: flex;
  padding: 6px;
  color: #4a5568;
}

.header {
  display: flex;
  height: 36px;
  padding: 10px 10px 0 10px;
  position: relative;
  flex-grow: 5;
  font-weight: var(--font-weight-fat);
}

.header:nth-child(n + 2):hover {
  border-radius: 6px;
  background: #edf2f7;
  cursor: pointer;
}

.header:first-child {
  flex-grow: 10;
}

.header:last-child {
  flex-grow: 0;
}

.header > :first-child {
  font-weight: inherit;
  flex-grow: 1;
}

.rest {
  --px: calc((100% - 500px) / 2);
  --pl: max(20px, var(--px));
  --pr: max(8px, var(--px) - 12px);
  height: calc(100% - 55px);
  padding: 20px var(--pr) 20px var(--pl);
  padding-top: 0;
  overflow-y: scroll;
}

.account {
  display: flex;
  justify-content: center;
}

.card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 100%;
  flex-direction: column;
}

.avatar {
  --avatar-size: 6rem;
}

.circle {
  margin: 0 auto;
  border-radius: 50%;
  width: var(--avatar-size);
  height: var(--avatar-size);
  text-align: center;
  background: #eceff1;
  background: #d8e6fe;
}

.initials {
  font-size: calc(var(--avatar-size) / 2);
  line-height: 1;
  position: relative;
  top: calc(var(--avatar-size) / 4);
  text-transform: uppercase;
  color: #607d8b;
  color: #565554;
  color: #6299f9;
}

.name {
  overflow: hidden;
  padding: 20px;
  min-width: 100%;
}

.recipient {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: capitalize;
  color: #222;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  text-align: center;
}

.recipient > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.recipient :nth-child(2) {
  margin-top: 10px;
  font-size: 14px;
  color: #757575;
}

.recipient :first-child:last-child {
  font-size: 24px;
}

.params {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 84px;
}

.pill {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 12px;
  border-radius: 999px;
  margin: 6px;
  background: #f3e5f5;
  color: #9c27b0;
  font-size: 12px;
}

.pill > * + * {
  margin-left: 5px;
}

.pill > :first-child {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 11px;
  font-weight: var(--font-weight-fat);
}

.pill > :last-child {
  text-transform: capitalize;
}

a.button {
  /*display: block;*/
  text-decoration: none;
}

.button {
  display: block;
  align-items: center;
  max-width: 498px;
  margin: 20px auto;
  border-radius: 10px;
  cursor: pointer;
  background: #e8f0fe;
  color: #1967d2;
  color: #2563eb;
  transition: height 200ms ease-out;
}

.button > :first-child {
  display: flex;
  padding: 0 20px;
  height: 58px;
  line-height: 58px;
  align-items: center;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button:not(.buttonOpen) > :first-child:hover {
  background: #d0e0fc;
}

.button:not(.buttonOpen) > :first-child:active {
  background: #b9d1fb;
}

.button > :first-child > :nth-child(2) {
  font-weight: var(--font-weight-bold);
  margin-left: 10px;
  margin-right: auto;
}

.button > :first-child > :nth-child(3) {
  transition: transform 200ms;
}

.buttonOpen > :first-child > :nth-child(3) {
  transform: rotate(180deg);
}

.button > :nth-child(n + 2):empty {
  opacity: 0;
  transition: none;
}

.button > :nth-child(n + 2) {
  opacity: 1;
  transition: opacity 333ms ease-out 200ms;
}

.buttonList {
  padding: 0 10px;
}

.buttonList > * {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.buttonList > :hover:not(.account) {
  background: #d0e0fc;
}

.buttonList > :active:not(.account) {
  background: #b9d1fb;
}

.buttonList > * > :nth-child(1) {
  height: 36px;
  width: 36px;
  flex-shrink: 0;
}

.buttonList > * > :nth-child(1):not(.card) {
  margin-left: 7px;
}

.buttonListIcon {
  background-size: cover;
  background-position: 50% 50%;
}

.buttonListLogo {
  border-radius: 999px;
  background-color: #fff;
  background-size: cover;
  background-position: 25% 50%;
}

.buttonListAvatar {
  overflow: hidden;
  border-radius: 999px;
  font-size: 20px;
  line-height: 26px;
  background: #d0e0fc;
  border: solid 5px #d0e0fc;
  text-transform: capitalize;
}

.buttonList > * > :nth-child(2) {
  flex-grow: 1;
  padding: 0 20px;
  overflow: hidden;
}

.buttonList > * > :nth-child(2) > :nth-child(1) {
  margin-bottom: 3px;
  font-size: 13px;
  font-weight: var(--font-weight-bold);
  text-transform: capitalize;
}

.buttonList > * > :nth-child(2) > :nth-child(2) {
  display: flex;
  align-items: center;
}

.buttonList > * > :nth-child(2) > :nth-child(2) > * {
  font-size: 12px;
}

.buttonList > * > :nth-child(2) > :nth-child(2) > :nth-child(odd) {
  flex-shrink: 0;
  margin-right: 5px;
}

.buttonList > * > :nth-child(2) > :nth-child(2) > :last-child {
  margin-right: 0px !important;
}

.buttonList > * > :nth-child(2) > :nth-child(2) > :nth-child(even) {
  flex-shrink: 1;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.buttonList > * > :nth-child(3) {
  padding: 5px;
  margin-right: 10px;
  font-size: 13px;
  font-weight: var(--font-weight-bold);
  border-radius: 5px;
  background: #d0e0fc;
  white-space: nowrap;
}

.buttonListSkeleton {
  display: flex;
  align-items: center;
  padding: 20px;
  height: 50px;
}

.buttonListSkeleton > * {
  width: 50%;
  height: 20px;
  background: #d0e0fc;
  border-radius: 999px;
}

.buttonListSkeleton > :first-child {
  height: 36px;
  width: 36px;
  margin-right: 20px;
}

.buttonListSkeleton > :last-child {
  width: 40px;
  margin-left: auto;
}

.buttonListEmpty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 15px;
}

.esignatureIcon {
  height: 20px;
  width: 20px;
  margin-right: -4px;
  stroke: #2563eb;
  stroke-width: 2;
}
