.outer {
  position: relative;
  padding: var(--app-border-size);
  padding-top: var(--app-border-top-size);
  padding-bottom: 0;
  overflow: hidden;
}

.inner {
  width: calc(100vw - var(--app-border-size) * 2);
  height: calc(
    var(--vh, 1vh) * 100 - var(--app-border-size) -
      var(--app-border-bottom-size)
  );
  border-radius: var(--app-border-radius);
  background: var(--app-background-color);
  overflow: hidden;
  transition: height 250ms;
  /*box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);*/
}

.lower {
  display: flex;
  height: var(--app-border-bottom-size);
  line-height: var(--app-border-bottom-size);
  padding: 0 5px;
  font-size: 10px;
  color: white;
  color: #e0f2f1;
  color: #00897b;
  color: #00838f;
  color: #004d40;
  color: #263238;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 9px;
  overflow: hidden;
}

.lower > :first-child {
  flex-grow: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 9px;
}

.lower > * + * {
  padding-left: 10px;
}

.warningDialog {
  /*border: solid 5px #ff8a80;*/
  background: #ff8a80;
  background: #ffcdd2;
  background: #f44336;
  color: white;
}

.warningBody {
  padding: 30px;
  padding-bottom: 10px;
}

.warningHeader {
  padding-bottom: 20px;
  font-size: 32px;
  font-weight: var(--font-weight-bold);
}

.warningReason {
  font-size: 18px;
  font-weight: var(--font-weight-bold);
}

.warningReport {
  flex-grow: 1;
  font-weight: var(--font-weight-bold);
}

.warningFooter {
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.warningFooter > * + * {
  margin-left: 15px;
}

.warningButton {
  padding: 13px;
  border-radius: 9px;
  box-shadow: 0 0 0 2px white;
}

.warningButton:first-of-type {
  font-weight: var(--font-weight-bold);
}

.warningButton:first-of-type:hover {
  background: white;
  color: red;
  font-weight: var(--font-weight-semi);
  box-shadow: 0 0 0 2px white;
}

.warningButton:last-of-type {
  background: white;
  color: red;
  font-weight: var(--font-weight-semi);
}

.warningButton:hover {
  background: #eee;
  box-shadow: 0 0 0 2px #eee;
}

.warningButton:active {
  background: #ddd !important;
  box-shadow: 0 0 0 2px #ddd !important;
}

.approveDialog {
  width: 350px;
}

.approveBody {
  padding: 40px;
  padding-bottom: 25px;
}

.approvePrimary {
  text-align: center;
  font-size: 28px;
  font-weight: var(--font-weight-bold);
  margin-bottom: 30px;
  /*text-transform: capitalize;*/
}

.approveSecondary:not(:empty) {
  text-align: center;
  flex-grow: 1;
  padding: 0.625rem 0;
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 30px;
}

.approveSecondary:empty {
  height: 20px;
}

.approveButtons {
  display: flex;
  justify-content: center;
}

.approveButtons > * + * {
  margin-left: 25px;
}

.approveCancel {
  height: 50px;
  padding: 1rem;
  border-radius: 0.375rem;
  background: #d1d5db;
  color: #111827;
  font-weight: 600;
}

.approveCancel:hover {
  background: #6b7280;
}

.approveOkey {
  height: 50px;
  padding: 1rem;
  border-radius: 0.375rem;
  background: #3b82f6;
  color: #f3f4f6;
  font-weight: 600;
}

.approveOkey:hover {
  background: #1d4ed8;
}

.dialogButton {
  user-select: none;
  padding: 1rem;
  border-radius: 0.375rem;
  background: #3b82f6;
  color: #f3f4f6;
  font-weight: var(--font-weight-bold);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}

.dialogButton:hover {
  background: #2563eb;
}
.dialogButton:active {
  background: #1d4ed8;
}

.offline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.offline > :first-child {
  font-size: 28px;
  margin-bottom: 35px;
}

.redirecting {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
}
.redirecting div {
  width: 100px;
  height: 100px;
}
.redirecting div:after {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  margin: -50px -50px;
  border-radius: 50%;
  border: 8px solid #444;
  border-color: #444 transparent #444 transparent;
  animation: redirecting 1s linear infinite;
}
@keyframes redirecting {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  height: 100%;
  display: grid;
}

.loading::before,
.loading::after {
  content: "";
  margin: auto;
  grid-area: 1 / 1 / 1 / 1;
  border: 4px solid #82b1ff;
  opacity: 1;
  border-radius: 50%;
  animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading::after {
  animation-delay: -0.5s;
}

@keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.skeleton {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.skeleton > * {
  background: white;
}
.skeleton > :nth-child(1) {
  width: 100%;
  height: 70px;
}
.skeleton > :nth-child(2) {
  height: calc(100% - 70px);
  width: 350px;
}
.skeleton > :nth-child(3) {
  flex-grow: 1;
  height: calc(100% - 110px);
  margin: 20px;
  border-radius: 10px;
}
