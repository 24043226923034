/*
:global(.real_estate_warning) .header:nth-child(2),
:global(.real_estate_warning) .item:nth-child(2) {
  display: none;
}

:global(.real_estate_danger) .header:nth-child(4),
:global(.real_estate_danger) .item:nth-child(4) {
  display: none;
}
*/

.outer {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.dropzone:not(.dropzoneOpa) {
  box-shadow: inset 0px 0px 0px 4px #2196f3;
}

.dropzone.red:not(.dropzoneOpa) {
  box-shadow: inset 0px 0px 0px 4px #f10603;
}

.tree {
  position: relative;
  height: calc(100% - 10px);
  overflow: hidden;
}

.bar {
  display: flex;
  height: 48px;
  padding: 6px;
  color: #4a5568;
}

.header {
  display: flex;
  height: 36px;
  padding: 10px 0;
  position: relative;
  flex-grow: 5;
  font-weight: var(--font-weight-fat);
  flex-basis: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header:hover {
  border-radius: 6px;
  background: #edf2f7;
  cursor: pointer;
}

.header:first-child {
  flex-grow: 10;
}

.header:last-child {
  flex-grow: 0;
  flex-basis: 38px;
}

.header > :first-child {
  font-weight: inherit;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header > :first-child {
  margin-left: 10px;
}

.header > :last-child {
  margin-right: 10px;
}

.header:empty {
  display: none;
}

.scroll {
  position: relative;
  height: calc(100% - 50px);
  padding: 0 10px;
  padding: 0 6px;
  overflow: auto;
  /*transition: height 350ms ease;*/
}

.scrollWithFooter {
  height: calc(100% - 150px);
}

.scrollWithOptions {
  height: calc(100% - 100px);
}

.footer {
  display: flex;
  align-items: center;
  background: #e2e8f0;

  height: 90px;
  margin: 10px;
  overflow: hidden;
  border-radius: 10px;
  transform: scale(0);
  transition: transform 350ms cubic-bezier(0, 0, 0.1, 1.1),
    opacity 350ms ease-out;
  transform-origin: top;
  opacity: 0;
}

.footer > div {
  flex-grow: 1;
}

.footerEnabled {
  transform: scale(1);
  opacity: 1;
}

.options {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  margin: 10px;
  padding: 10px;

  user-select: none;
  border-radius: 10px;
  background: #e2e8f0;
  color: #1e293b;
}

.options span {
  font-weight: 600;
}

.options > * + * {
  margin-left: 10px;
}

.placeholder {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: 50px;
  width: 37%;
  border-radius: 10px;
  box-shadow: inset 0px 0px 0px 3px #ffffff00;
  transform: translate(10px, 3px);
}

.placeholder.around {
  box-shadow: 0px 0px 0px 3px #2196f3;
  box-shadow: inset 0px 0px 0px 3px #e2e8f0;
  transition: box-shadow 150ms ease 100ms;
}

.isCombining .placeholder,
.isDraggingOverNot .placeholder {
  box-shadow: inset 0px 0px 0px 3px #ffffff00 !important;
}

.draggable {
  will-change: transform;
}

.wrapper {
  /* padding: 3px 13px 3px 12px;*/
  padding: 3px 0;
  will-change: transform;
}

.wrapper,
.wrapper * {
  cursor: grab;
}

.isDropping .row {
  box-shadow: inset 0px 0px 0px 3px #2196f3;
}

.dropzone .row {
  transition: none;
}

.row {
  position: relative;
  z-index: 2;
  height: 50px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
}

.row:hover {
  background: #edf2f7;
}

.isDraggingOverNot .current .row {
  background: #2196f3;
}

.isDraggingOverNot .current .row .item span {
  color: white;
}

.dragging .row {
  /*padding: 0 !important;*/
  width: max-content;
  max-width: 300px;
  background: #2196f3;
  color: white;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.row > :first-child > :first-child {
  /*transition: width 100ms;*/
}

.dragging .row > :first-child > :first-child {
  width: 0 !important;
}

.dragging .row > :nth-child(n + 2) {
  display: none;
}

.item {
  height: 50px;
  line-height: 50px;
  flex-grow: 5;
  flex-basis: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item > :first-child::before {
  display: inline-block;
  content: "";
  width: 5px;
}

.item:first-child {
  display: flex;
  flex-grow: 10;
}

.item:first-child > :first-child {
  flex-shrink: 0;
}

.hoverholder {
  flex-grow: 1;
  height: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: inset 0px 0px 0px 3px #ffffff00;
  transition: box-shadow 150ms ease 100ms;
  overflow: hidden;
}

.dropping .hoverholder {
  box-shadow: 0px 0px 0px 3px #2196f3;
  box-shadow: inset 0px 0px 0px 3px #e2e8f0;
}

.hoverholder > :first-child {
  margin-right: 5px;
  flex-shrink: 0;
}

.hoverholder > :last-child {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item:nth-child(n + 2) > * {
  margin: 5px;
}

.item:last-child {
  flex-grow: 0;
  flex-basis: 38px;
}

.item:nth-child(n + 2) {
  font-size: 12px;
  color: #555;
}

.hidden {
  visibility: hidden;
}

.noneAttest {
  visibility: hidden;
}

.noneAttest.attest {
  visibility: visible;
  color: #2196f3;
  color: #b0bec5;
}

.reject {
  color: #ff1744 !important;
}

.current .reject {
  color: #ff8a80 !important;
  color: #ffcdd2 !important;
}

.approve {
  color: #00c853 !important;
}

.current .approve {
  color: #b9f6ca !important;
}

.signable {
  color: #ff9800 !important;
}

.current .signable {
  color: #ffecb3 !important;
}

/* ESIGNATURE */

.noEsignature {
  visibility: hidden;
}

.noEsignature.esignature {
  visibility: visible;
  color: #b0bec5;
}

.canceled {
  color: #ff1744 !important;
}

.current .canceled {
  color: #ffcdd2 !important;
}

.iHaveSigned {
  color: #00c853 !important;
}

.signed {
  color: #00c853 !important;
}

.current .signed {
  color: #b9f6ca !important;
}

.more {
  text-align: right;
  padding: 7px 4px 0 0;
}

.narrow {
  display: flex;
  align-items: center;
  flex-grow: 2;
}

.item.narrow {
  /*justify-content: center;*/
}

.error {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.errorInput {
  box-shadow: 0 0 0 4px #ff5252;
}

.beforeDrop {
  padding-left: 20px;
}

.dropToUpload {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: var(--font-weight-fat);
}

.uploadImage {
  display: none;
  width: 64px;
  height: 70px;
  position: relative;
}

.uploadImage svg {
  position: absolute;
}

.uploadImage [data-icon="cloud-upload"] {
  top: 20px;
  left: 5px;
  width: 50px;
  height: 50px;
  color: #2196f3;
}

.uploadImage [data-icon="shield"] {
  top: 0;
  left: 38px;
  width: 26px;
  height: 26px;
  color: #4caf50;
}

.uploadImage [data-icon="clean"] {
  top: 2px;
  left: 0;
  width: 22px;
  height: 22px;
  transform: scaleX(-1);
  color: #ffc107;
}

.newFolderDialog {
  padding: 1rem;
}

.dialogTitle {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #1f2937;
  font-weight: 400;
}

.dialogBody {
  padding: 0.75rem;
  padding-top: 2rem;
}

.dialogBody > * + * {
  margin-top: 2.5rem;
}

.dialogInput {
  background: #e5e7eb;
  background: #edf2f7;
  display: block;
  padding: 1.25rem;
  width: 100%;
  border-radius: 0.5rem;
  appearance: none;
  line-height: 1.5;
  color: #1f2937;
  font-weight: 400;
}

.dialogInput:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.dialogInput::placeholder {
  color: #4b5563;
}

.dialogButton {
  padding: 1rem;
  border-radius: 0.375rem;
  float: right;
  background: #3b82f6;
  color: #f3f4f6;
  font-weight: 600;
}

.dialogButton:hover {
}

.upsideDown {
  transform: rotate(180deg);
}

.splash {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rest {
  flex: 1 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty {
  display: flex;
  flex-direction: column;
  color: #263238;
  text-align: center;
}

.empty > :first-child {
  color: #ff5252;
  color: #2196f3;
}

.empty > :last-child {
  color: #757575;
  font-size: 13px;
}

.empty > :last-child > :first-child {
  color: #212121;
  padding: 15px 0 10px 0;
  font-size: 19px;
  font-weight: var(--font-weight-bold);
}
