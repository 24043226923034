.outer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bar {
  height: 50px;
  display: flex;
  padding: 6px;
  color: #4a5568;
}

.header {
  display: flex;
  height: 38px;
  padding: 10px 10px 0 10px;
  position: relative;
  flex-grow: 5;
  font-weight: var(--font-weight-fat);
}

.header:nth-child(n + 2):hover {
  border-radius: 6px;
  background: #edf2f7;
  cursor: pointer;
}

.header:first-child {
  flex-grow: 10;
}
.header:last-child {
  flex-grow: 0;
}
.header > :first-child {
  font-weight: inherit;
  flex-grow: 1;
}

.rest {
  flex-grow: 1;
  margin-bottom: 5px;
  overflow: auto;
}

.item {
  display: flex;
  height: 80px;
  align-items: center;
}

.icon {
  margin-left: 18px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trash {
  margin: 12px;
  padding: 7px 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.trash:hover {
  background: #eee;
}

.trash {
  margin: 0;
  width: 15px;
  visibility: hidden;
}

.meter {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.upper,
.lower,
.middle {
  display: flex;
}

.middle {
}

.meter .upper,
.meter .lower {
  padding: 5px;
}

.meter .middle {
  padding: 0 5px;
}

.error {
  color: red;
}

.meter .text,
.meter .percent,
.meter .size,
.meter .speed {
  flex-grow: 1;
}

.meter .text {
  font-weight: var(--font-weight-bold);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.meter {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meter .percent {
  text-align: right;
}

.meter .speed {
  text-align: right;
}

.meter .progress {
  background: #42a5f5;
  width: 0%;
  height: 6px;
  border-radius: 999px;
  transition: width 500ms;

  /*width: 33% !important;*/
}

.meter .progress.done {
  background: #8bc34a;
}

.meter .progress:not(.done):not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.meter .progress + * {
  display: block;
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 999px;
  background: #eee;
  transition: width 500ms;
}

.meter .progress:not(.zero) + * {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.summary {
  height: 80px;
  padding: 0 20px 0 5px;
}

.summary .trash {
  display: none;
}

.summary .progress {
  height: 10px;
}

.summary .progress + * {
  height: 10px;
  background: #f5f5f5;
  background: #cbd5e1;
}

.summary .icon {
  margin-right: 15px;
}

.summary .file > * > * {
  box-shadow: 0px 0px 0px 1px #0000001a;
  box-shadow: 0px 0px 0px 1px white;
  box-shadow: 0px 0px 0px 1px #f5f5f5;
}
