.styleDoc {
  width: 100%;
  height: 100%;
}

.loadmask {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: none;
  background: #e2e2e2;
  z-index: 1001;
}
.loadmask > .brendpanel {
  width: 100%;
  min-height: 28px;
  background: #446995;
}
.loadmask > .brendpanel > div {
  display: flex;
  align-items: center;
  height: 28px;
}
.loadmask > .brendpanel .spacer {
  margin-left: auto;
}
.loadmask > .brendpanel .rect {
  vertical-align: middle;
  width: 50px;
  height: 12px;
  border-radius: 3px;
  margin: 0 10px;
  background: rgba(255, 255, 255, 0.2);
  animation: flickerAnimation 2s infinite ease-in-out;
}
.loadmask > .sktoolbar {
  background: #f1f1f1;
  border-bottom: 1px solid #cfcfcf;
  height: 46px;
  padding: 10px 12px;
  box-sizing: content-box;
}
.loadmask > .sktoolbar ul {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  position: relative;
}
.loadmask > .sktoolbar li {
  background: #e2e2e2;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 6px;
}
.loadmask > .sktoolbar li.space {
  background: none;
  width: 12px;
}
.loadmask > .sktoolbar li.fat {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 612px;
  width: inherit;
  height: 44px;
}
.loadmask > .sktoolbar li:not(.space) {
  animation: flickerAnimation 2s infinite ease-in-out;
}
.loadmask > .placeholder {
  background: #fbfbfb;
  width: 794px;
  margin: 46px auto;
  height: calc(100% - 46px - 28px - 46px * 2);
  border: 1px solid #bebebe;
  padding-top: 50px;
  overflow: hidden;
}
.loadmask > .placeholder > .line {
  height: 15px;
  margin: 30px 80px;
  background: #e2e2e2;
  overflow: hidden;
  position: relative;
  animation: flickerAnimation 2s infinite ease-in-out;
}

.styleSlides {
  width: 100%;
  height: 100%;
}

.loadmask {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: none;
  background-color: #e2e2e2;
  z-index: 1001;
}
.loadmask > .brendpanel {
  width: 100%;
  min-height: 28px;
  background: #aa5252;
}
.loadmask > .brendpanel > div {
  display: flex;
  align-items: center;
  height: 28px;
}
.loadmask > .brendpanel .spacer {
  margin-left: auto;
}
.loadmask > .brendpanel .rect {
  vertical-align: middle;
  width: 50px;
  height: 12px;
  border-radius: 3px;
  margin: 0 10px;
  background: rgba(255, 255, 255, 0.2);
  animation: flickerAnimation 2s infinite ease-in-out;
}
.loadmask > .sktoolbar {
  background: #f1f1f1;
  border-bottom: 1px solid #cbcbcb;
  height: 46px;
  padding: 10px 12px;
  box-sizing: content-box;
}
.loadmask > .sktoolbar ul {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  position: relative;
}
.loadmask > .sktoolbar li {
  background: #ececec;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 6px;
}
.loadmask > .sktoolbar li.space {
  background: none;
  width: 12px;
}
.loadmask > .sktoolbar li.big {
  width: 60px;
  height: 46px;
  margin-top: -46px;
}
.loadmask > .sktoolbar li.fat {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 855px;
  width: inherit;
  height: 44px;
}
.loadmask > .sktoolbar li:not(.space) {
  animation: flickerAnimation 2s infinite ease-in-out;
}
.loadmask > .placeholder {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0 100px;
}
.loadmask > .placeholder .slideH {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  max-width: 1350px;
  width: 100%;
  margin: 0 auto 126px;
}
.loadmask > .placeholder .slideV {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 56.1333%;
}
.loadmask > .placeholder .slideContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fbfbfb;
  border: 1px solid #dfdfdf;
}
.loadmask > .placeholder .slideContainer > .line {
  height: 20%;
  margin: 0 120px;
  border-radius: 6px;
  background: #f5f5f5;
  animation: flickerAnimation 2s infinite ease-in-out;
}
.loadmask > .placeholder .slideContainer > .line.empty {
  background: transparent;
}
.loadmask > .placeholder .slideContainer > .line:nth-child(1) {
  height: 30%;
  margin: 10% 80px 0;
}

.styleSheets {
  width: 100%;
  height: 100%;
}
.loadmask {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: none;
  background-color: #f4f4f4;
  z-index: 1001;
}
.loadmask > .brendpanel {
  width: 100%;
  min-height: 28px;
  background: #40865c;
}
.loadmask > .brendpanel > div {
  display: flex;
  align-items: center;
  height: 28px;
}
.loadmask > .brendpanel .spacer {
  margin-left: auto;
}
.loadmask > .brendpanel .rect {
  vertical-align: middle;
  width: 50px;
  height: 12px;
  border-radius: 3px;
  margin: 0 10px;
  background: rgba(255, 255, 255, 0.2);
  animation: flickerAnimation 2s infinite ease-in-out;
}
.loadmask > .sktoolbar {
  background: #f1f1f1;
  height: 46px;
  padding: 10px 12px;
  box-sizing: content-box;
}
.loadmask ul {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  position: relative;
}
.loadmask > .sktoolbar li {
  background: #e2e2e2;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 6px;
}
.loadmask > .sktoolbar li.space {
  background: none;
  width: 12px;
}
.loadmask > .sktoolbar li.fat {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 905px;
  width: inherit;
  height: 44px;
}
.loadmask > .sktoolbar li:not(.space) {
  animation: flickerAnimation 2s infinite ease-in-out;
}
.loadmask > .skformula {
  height: 24px;
  background: #f1f1f1;
}
.loadmask > .skformula ul {
  list-style-type: none;
  font-size: 0;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: #e1e1e1 transparent;
}
.loadmask > .skformula li {
  display: inline-block;
  box-sizing: border-box;
  height: 19px;
  width: 100%;
  margin-left: 20px;
  background: #f7f7f7;
  border-style: solid;
  border-width: 1px;
  border-color: transparent #e1e1e1;
}
.loadmask > .skformula li:first-child {
  width: 100px;
  margin-left: 0;
}
.loadmask > .placeholder {
  background: #fbfbfb;
  width: 100%;
  height: 100%;
  font-size: 0;
  border: 1px solid #dfdfdf;
  white-space: nowrap;
  animation: flickerAnimation 2s infinite ease-in-out;
}
.loadmask > .placeholder > .columns {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(90deg, #d5d5d5 0px, rgba(223, 223, 223, 0) 1px) 0
      0,
    linear-gradient(rgba(223, 223, 223, 0) 19px, #d5d5d5 20px) 0 0,
    linear-gradient(#f1f1f1 0px, #f1f1f1 20px) 0 0 repeat-x;
  background-size: 80px 20px;
}
.loadmask > .placeholder > .columns:first-child {
  background: linear-gradient(#f1f1f1 19px, #d5d5d5 20px) 0 0;
  background-size: 20px 20px;
  width: 25px;
}

@keyframes flickerAnimation {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
