.outer {
  height: 100%;
}

.bar {
  height: 48px;
  display: flex;
  padding: 6px;
  color: #4a5568;
}

.header {
  display: flex;
  height: 37px;
  padding: 10px 10px 0 10px;
  position: relative;
  flex-grow: 5;
  font-weight: var(--font-weight-fat);
}

.header:nth-child(n + 2):hover {
  border-radius: 6px;
  background: #edf2f7;
  cursor: pointer;
}

.header:first-child {
  flex-grow: 10;
}
.header:last-child {
  flex-grow: 0;
}
.header > :first-child {
  font-weight: inherit;
  flex-grow: 1;
}

.rest {
  height: calc(100% - 55px);
  max-width: 500px;
  padding: 20px;
  margin: 0 auto;
  overflow: auto;
}

.empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #263238;
  text-align: center;
}

.empty > :first-child {
  color: #ff5252;
  color: #2196f3;
}

.empty > :last-child {
  color: #757575;
  font-size: 13px;
}

.empty > :last-child > :first-child {
  color: #212121;
  padding: 15px 0 10px 0;
  font-size: 19px;
  font-weight: var(--font-weight-bold);
}

.file {
  display: flex;
  padding: 10px;
  border-radius: 999px;
  align-items: center;
  background: #eceff1bf;
  color: #37474f;
}

.icon {
  margin: 5px 10px;
}

.name {
  --lh: 22px;
  line-height: var(--lh);
  font-size: 20px;
  overflow: hidden;
}
.textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncateOverflow {
  font-weight: inherit;
  --max-lines: 2;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 30px;
}
.truncateOverflow::before {
  font-weight: inherit;
  position: absolute;
  content: "...";
  inset-block-end: 0;
  inset-inline-end: 0;
}
.truncateOverflow::after {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  width: 30px;
  height: 30px;
  background: white;
}

.params {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.br {
  flex-basis: 100%;
}

.pill {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 15px;
  border-radius: 999px;
  margin: 10px;
  font-size: 14px;
  overflow: hidden;
}

.pill > * + * {
  margin-left: 10px;
}

.pill > :first-child {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 11px;
  font-weight: var(--font-weight-fat);
}

.pill > :last-child {
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pill:nth-of-type(1) {
  background: #f3e5f5;
  color: #9c27b0;
}

.pill:nth-of-type(2) {
  background: #ede7f6;
  color: #673ab7;
}

.pill:nth-of-type(3) {
  background: #e0f7fa;
  color: #0097a7;
}

.pill:nth-of-type(4) {
  background: #e0f2f1;
  color: #009688;
}

.pill:nth-of-type(5) {
  background: #f9fbe7;
  color: #9e9d24;
}

.button {
  height: 58px;
  line-height: 58px;
  max-width: 498px;
  margin: 20px auto;
  padding: 0 20px;
  border-radius: 8px;
  cursor: pointer;
  background: #3b82f6;
  background: #e8f0fe;
  color: #f3f4f6;
  color: #1967d2;
}

.button:hover {
  background: #2563eb;
  background: #d0e0fc;
}

.button:active {
  background: #cbd5e0;
  background: #1d4ed8;
  background: #b9d1fb;
}

.button > * + * {
  margin-left: 10px;
}

.button > :last-child {
  font-weight: var(--font-weight-bold);
}

/*************************************/

.version {
  height: 50px;
  margin: 20px 0;
  display: flex;
}

.version > :nth-child(n + 2):nth-last-child(n + 2) {
  line-height: 50px;
  display: inline-block;
  flex-grow: 3;
  flex-basis: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 3px;
  color: #000000d9;
}

.version > :nth-child(2),
.version > :nth-last-child(2) {
  flex-grow: 2 !important;
}

.version > :nth-child(2) {
  font-weight: var(--font-weight-bold);
}

.version > :nth-last-child(2) {
  text-align: right;
}

.version > :first-child,
.version > :last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00000099;
}

.version > :first-child {
  margin: 5px;
  width: 40px;
  margin-right: 10px;
  background: #ffd54f;
  border-radius: 999px;
}

.version > :last-child {
  margin: 7px;
  width: 36px;
  margin-left: 10px;
  background: #64b5f6;
  border-radius: 10px;
  cursor: pointer;
}

.version > :last-child:hover {
  background: #42a5f5;
}

.version > :last-child:active {
  background: #2196f3;
}

.version:last-child > :first-child {
  background: #ff8a65;
}
