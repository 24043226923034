.Skeleton {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  z-index: 20;
  background: #f5f5f5;
}

.Skeleton > :first-child {
  display: flex;
  height: 60px;
  padding: 0 20px;
  align-items: center;
  background: white;
}

.Skeleton > :first-child > * {
  height: 30px;
  width: 100px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #eee;
  /*opacity: 0.1;*/
  /*animation: flicker 2s infinite ease-in-out;*/
}

.Skeleton > :first-child > * + * {
  margin-left: 20px;
}

.Skeleton > :first-child > :first-child {
  height: 40px;
}

.Skeleton > :first-child > :nth-child(2) {
  height: 20px;
  width: 500px;
  margin-right: 20px;
  flex-shrink: 1;
}

.Skeleton > :first-child > :nth-child(3) {
  margin-left: auto;
}

.Skeleton > :first-child > :last-child {
  width: 30px;
}

.Skeleton > :last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.Skeleton > :last-child > * {
  flex-shrink: 1;
  width: 800px;
  height: calc(100% - 60px);
  margin: 30px;
  background: white;
  border-radius: 5px;
}

.Skeleton > :last-child > * > * {
  background: #eee;
  margin: 20px;
  border-radius: 5px;
}

.Skeleton > :last-child > * > :nth-child(1) {
  width: 100px;
  height: 100px;
}

.Skeleton > :last-child > * > :nth-child(2) {
  width: 200px;
  height: 20px;
}

.Skeleton > :last-child > * > :nth-child(3) {
  width: 150px;
  height: 20px;
}

.Skeleton :empty {
  background-image: linear-gradient(to right, #eee 20%, #e0e0e0 50%, #eee 80%);
  background-repeat: no-repeat;
  background-size: 200%;
  animation: shimmer 2s linear infinite;
}

@keyframes shimmer {
  0% {
    background-position: 200%;
  }
  100% {
    background-position: -400%;
  }
}
