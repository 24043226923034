.zoneUsers {
  position: relative;
  width: 20px;
  height: 27px;
}

.zoneUsers svg {
  position: absolute;
  stroke: #616161;
}

.zoneUsers > :first-child {
  top: 0px;
  left: -4px;
}

.zoneUsers > :last-child {
  top: 7px;
  left: 1px;
}

.folderUsers {
  position: relative;
  width: 20px;
  height: 27px;
}

.folderUsers svg {
  position: absolute;
  stroke: #616161;
}

.folderUsers > :first-child {
  top: 0px;
  left: -2px;
}

.folderUsers > :last-child {
  top: 7px;
  left: 1px;
}

.blocked > :first-child {
  fill: #1a237e;
  fill: #2962ff;
}

.blocked > :last-child {
  fill: #f44336;
}

.outer {
  display: inline-block;
}

.users {
  stroke: #263238;
  stroke-width: 1.5px;
}

.trash {
  stroke: #9e9e9e;
}

.trash :nth-child(2) {
  stroke: #bdbdbd;
}

.fingerprint {
  display: inline-block;
}

.fingerprint :first-child {
  stroke: #90a4ae;
  stroke-width: 1.5px;
}

.fingerprint :last-child {
  stroke: #64b5f6;
  stroke-width: 1.5px;
}

.info {
  display: inline-block;
}

.info :first-child {
  stroke: #9e9e9e;
  stroke-width: 1.5px;
}

.info :last-child {
  stroke: #e0e0e0;
  stroke-width: 1.5px;
}

.click {
  display: inline-block;
}

.click :first-child {
  stroke: #fbc02d;
  stroke-width: 2px;
}

.click :last-child {
  stroke: #bdbdbd;
  fill: #bdbdbd;
}

.squircle {
  padding: 2px;
  /*
  width: 44px;
  height: 44px;
  */
}

/*
.squircle svg {
  width: 40px;
  height: 40px;
}
*/

.addUser {
  background: #2979ff;
  padding: 4px;
}

.addUser svg {
  stroke: white;
}

.addZone {
  background: #2979ff;
  padding: 7px;
}

.addZone svg {
  fill: white;
}

.change {
  background: #2979ff;
  padding: 4px;
}

.change svg {
  stroke: white;
}

.restrict {
  background: #2979ff;
  padding: 4px;
}

.restrict svg {
  stroke: white;
}

.email {
  background: #bbdefb;
  background: #2962ff;
}

.email svg {
  stroke: #0d47a1;
  stroke: white;
}

.twofa {
  background: #c8e6c9;
  background: #00c853;
}

.twofa svg {
  stroke: #1b5e20;
  stroke: white;
}

.sso {
  padding: 4px;
  background-color: #f3f3f3;
}

.cancel {
  padding: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cancel:hover {
  background: #eee;
}
