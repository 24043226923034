.icon {
  --transition-duration: 0.2s;
  position: relative;
  box-sizing: content-box;
  height: 36px;
  width: 36px;
  cursor: pointer;
}
.icon p {
  font-size: 7px;
  line-height: 9px;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.doc {
  position: relative;
  height: 36px;
  width: 32px;
  border-radius: 7px;
}

.doc svg {
}
.folder {
  --bg-color: #ffc400;
  --fg-color: #ffd740;
  --symbol-color: #f9a825;
  --tag-color: #64b5f6;

  position: absolute;
  bottom: 0px;
  height: 28px;
  width: 32px;

  background: var(--bg-color);
  border-radius: 0 7px 7px 7px;
  transition: all var(--transition-duration);
}
.tab {
  position: absolute;
  top: -6px;
  width: 24px;
  height: 6px;
  background: var(--bg-color);
  border-radius: 7px 7px 0 0;
  transition: all var(--transition-duration);
}
.tag {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 25px;
  height: 20px;
  border-radius: 10px;
  background: var(--tag-color);
  transition: all var(--transition-duration);
}
.lid {
  width: 32px;
  height: 100%;
  position: absolute;
  background: var(--fg-color);
  border-radius: 7px;
  transition: all var(--transition-duration);
  transform-origin: right bottom;
}
.folder .img {
  position: absolute;
  top: 1px;
  left: 4px;
  width: 25px;
  height: 25px;
  fill: var(--symbol-color);
  transition: transform var(--transition-duration);
}
/*.grp {
  fill: #616161;
  transform: translate(0, 1px);
}*/
.folder .img.grp {
  fill: none;
  stroke: #616161;
  stroke: #3e2723;
  stroke-width: 1.5px;
  top: 4px;
  left: 7px;
  width: 19px;
  height: 19px;
}
.full {
  height: 26px;
}
.full .tab {
  top: -8px;
  height: 8px;
}
.full .tag {
  border-radius: 5px;
  top: -5px;
  left: 3px;
}
.open {
  /*transition: all var(--transition-duration) /*, width 0s*/
}

.open .lid {
  border-radius: 5px 8px 6px 6px;
  transform: scale(1, 0.8) skew(-15deg, 0);
  /*border-left: none;*/
}

.open .img {
  transform: translate(4px, 4px) skew(-20deg, 0);
}
.open .grp {
  transform: scale(0.9) translate(3px, 4px) skew(-10deg, 0);
}

.full.open {
  width: 19px;
}

.full.open .tag {
  top: -2px;
  left: 3px;
  width: 28px;
  height: 23px;
  border-radius: 4px 5px 0 10px;
  transform: skew(-10deg, 0);
}

.open:not(.full) .lid {
}

/* lat sta

.iconBase {
  width: 50px;
  height: 50px;
  background: red;
}

.iconBase.animated {
  transform: scale(1);
  transition: transform 1s;
}

.iconBase.animated.animate {
  transform: scale(1.1);
}

.iconBounce {
    height: 50px;
    width: 50px;
    background-color: #F44336;
}
.iconBounce.animated {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
}
.iconBounce.animate {
    animation-name: bounce-7;
    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}
@keyframes bounce-7 {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-100%); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-7%); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
}

.iconOpen {
  position: relative;
}

.iconOpen > * {
  position: absolute;
}

.iconOpen > :first-child {
  top: 0;
  width: 50px;
  height: 50px;
  background: red;
}
.iconOpen > :last-child {
  width: 50px;
  height: 50px;
  background: green;
}

.iconOpen.animated > :last-child {
  transform-origin: 0 0;
  transition: transform 1s;
}

.iconOpen.animateIn > :last-child {
  transform: skew(-20deg, 0) translate(20px, 15px) scale(1, 0.7);
}
*/
