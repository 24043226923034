.outer {
  height: 100%;
  display: flex;
  border-radius: 10px;

  --anif-slide: ease;
  --anif-leave-opacity: ease;
  --anif-leave-scale: ease;
  --anif-appear-opacity: ease;
  --anif-appear-scale: cubic-bezier(0.54, 1.12, 0.38, 1.11);
  --anif-appear-scale: ease;
}

.left {
  flex-shrink: 0;
  background: var(--tile-background);
  box-shadow: var(--tile-shadow);
  box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.05);
  /*transition: width 250ms;*/
}

.right {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.top {
  height: 70px;
  border-left: solid 2px var(--app-background-color);
  background: var(--tile-background);
  box-shadow: var(--tile-shadow);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  /*transition: height 250ms;*/
}

.middle {
  flex-grow: 1;
  display: flex;
  padding: 0 calc(var(--tile-margin) / 2);
}

.bottom {
  height: 70px;
  border-left: solid 1px var(--app-background-color);
  background: var(--tile-background);
  box-shadow: var(--tile-shadow);
}

.aaa {
  flex-grow: 2;
}

.vip {
  flex-grow: 3;
}

.bbb {
  position: absolute;
  transition: all 300ms var(--anif-slide);
}

.rm .bbb {
  transition: all 300ms var(--anif-slide) 100ms;
}

.tile {
  flex-grow: 1;
  height: calc(100% - var(--tile-margin) - var(--tile-margin-top));
  border-top-left-radius: var(--tile-border-radius-top);
  border-top-right-radius: var(--tile-border-radius-top);
  border-bottom-right-radius: var(--tile-border-radius);
  border-bottom-left-radius: var(--tile-border-radius);
  margin: var(--tile-margin) calc(var(--tile-margin) / 2);
  margin-top: var(--tile-margin-top);
  background: var(--tile-background);
  box-shadow: var(--tile-shadow);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  /*overflow: hidden;*/
}

.appear {
}

.appearActive {
}

.appearDone {
}

.enter .bbb,
.enterDone .bbb {
  will-change: opacity, transform;
}

.enter .bbb {
  opacity: 0;
  transform: scale(0.9);
}

.enterActive .bbb {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms var(--anif-appear-opacity) 200ms,
    transform 300ms var(--anif-appear-scale) 200ms;
}

.exit .bbb {
  opacity: 1;
  transform: scale(1);
}

.exitActive .bbb {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200ms var(--anif-leave-opacity),
    transform 200ms var(--anif-leave-scale);
}

.exitDone {
}

.exitActive.aaa {
  background: red;
  max-width: 0px;
}
