.outer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bar {
  display: flex;
  height: 50px;
  padding: 6px;
  color: #4a5568;
}

.header {
  display: flex;
  height: 38px;
  padding: 10px 10px 10px 10px;
  flex-grow: 5;
  flex-basis: 0;
  font-weight: var(--font-weight-fat);
}

.header:hover {
  border-radius: 6px;
  background: #edf2f7;
  cursor: pointer;
}

.header > :first-child {
  font-weight: inherit;
  flex-grow: 1;
}

.header > :not(:first-child):not(:last-child) {
  background: #ffecb3;
  color: #ff6f00;
  border-radius: 999px;
  padding: 2px 5px;
  font-size: 11px;
  margin-right: 15px;
  text-transform: capitalize;
}

.header:nth-last-child(-n + 2) {
  flex-grow: 0;
}

.header:empty {
  display: none;
}

.rest {
  padding: 12px 16px 22px 16px;
}

.search {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  height: 40px;
  border-radius: 999px;
  background: #d1d5db;
  background: #e2e8f0;
  color: #374151;
}

.search input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 40px;
  background: transparent;
  width: 100%;
  height: 100%;
}

.search > :nth-child(2) {
  position: absolute;
  top: 11px;
  left: 13px;
  pointer-events: none;
}

.search > :nth-child(3) {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  border-radius: 999px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.search :global .bp3-spinner svg {
  stroke-width: 10px !important;
}

.search :global .bp3-spinner .bp3-spinner-track {
  stroke: transparent !important;
}

.search :global .bp3-spinner .bp3-spinner-head {
  stroke: #1a237e !important;
}

.search > :nth-child(3):hover {
  background: #00000010;
}

.activityList {
  overflow-y: scroll;
  padding: 0 10px;
  margin-bottom: 5px;
}

.activityList > * + * {
  margin-top: 10px;
}

.activitySticky {
  background-color: #6bd5ff !important;
}

.activityEntryContent {
  display: flex;
  height: 64px;
  cursor: pointer;
  border-radius: 17px;
}

/* .activityEntryContent::before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0,0,0,0.05) 20%, #d9d9d9 40%, #d9d9d9 100%);
  background-repeat: no-repeat;
  background-size: 450px 400px;
  animation: shimmer 1s linear infinite;
} */

.activityEntryContent:hover {
  background: #e2e8f0;
}

.activityEntryIcon {
  flex: 0 0 70px;
  display: inline-block;
  vertical-align: top;
}

.squircle {
  padding: 10px;
  background: red;
  width: 40px;
  height: 40px;
  margin: 12px;
  color: white;
}

.activityEntryText {
  display: flex;
  flex-direction: column;
  flex: 3 1 0;
  padding: 10px 0;
  overflow: hidden;
}

.activityEntryText > * {
  text-transform: capitalize;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.activityEntryText h1 {
  font-size: 13px;
}

.activityEntryText h1:nth-last-child(2) {
  font-size: 14px;
}

.activityEntryText h1:last-child {
  font-size: 16px;
  line-height: 44px;
}

.activityEntryText h2 {
  font-size: 12px;
}

.activityEntryText h2:last-child {
  font-size: 13px;
}

.activityEntryText h3 {
  font-size: 11px;
}

.activityEntryMeta {
  display: flex;
  flex-direction: column;
  flex: 2 1 0;
  text-align: right;
  padding: 10px;
  overflow: hidden;
}

.activityEntryMeta > * {
  text-transform: capitalize;
  flex: 1 1 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.activityEntryMeta > :empty {
  display: none;
}

.activityEntryWhen {
  font-size: 13px;
  font-weight: var(--font-weight-bold);
}

.activityEntryWho {
  font-size: 11px;
}

.activityEntryWhere {
  font-size: 11px;
}

.activityDone {
  height: 50px;
  line-height: 50px;
  margin: 10px 0;
  border-radius: 10px;
  text-align: center;
  background: #f3f4f6;
  color: #9da3af;
}

.activityMore {
  height: 50px;
  line-height: 50px;
  margin: 10px 0;
  border-radius: 10px;
  text-align: center;
  background: #f3f4f6;
  color: #6b7280;
  color: #9da3af;
  cursor: pointer;
}

.activityMore:hover {
  background: #e5e7eb;
}

.activityMore:active {
  background: #d1d5db;
}

.skeleton {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 64px;
  cursor: pointer;
  border-radius: 17px;
  background: white;
  padding-right: 10px;
}

.skeletonSquircle {
  padding: 10px;
  background: lightgray;
  width: 40px;
  height: 40px;
  margin: 12px;
}

.skeletonAnimation {
  height: 64px;
  width: 100%;
  background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    linear-gradient(lightgray 12px, transparent 0),
    linear-gradient(lightgray 12px, transparent 0);
  background-repeat: no-repeat;
  background-size: 20px 200px, 100% 200px, 100% 200px;
  background-position: 0 0, 10px 12px, 10px 38px;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  to {
    background-position: 100% 0, 10px 12px, 10px 38px;
  }
}
