/*
:global(.real_estate_warning) .header:nth-child(2),
:global(.real_estate_warning) .row > :nth-child(2) {
  display: none;
}
*/

:global(.real_estate_danger) .header:nth-child(2),
:global(.real_estate_danger) .row > :nth-child(2) {
  display: none;
}

.outer {
  height: 100%;
}

.bar {
  display: flex;
  height: 50px;
  padding: 6px;
  color: #4a5568;
}

.header {
  display: flex;
  height: 38px;
  padding: 10px;
  flex-grow: 5;
  flex-basis: 0;
  font-weight: var(--font-weight-fat);
  align-items: center;
}
.header:hover {
  border-radius: 6px;
  background: #edf2f7;
  cursor: pointer;
}

.disabled {
  color: whitesmoke;
}

.header:first-child {
  flex-grow: 10;
}
.header:nth-child(4) {
  flex-grow: 0;
  margin-left: 10px;
}

.header > :first-child {
  font-weight: inherit;
  flex-grow: 1;
}

.row {
  display: flex;
  align-items: center;
  height: 50px;
  margin: 0 6px;
  padding: 0 10px;
  border-radius: 6px;
  cursor: pointer;
}

.row:hover {
  background: #edf2f7;
  background: #e2e8f0;
}

.row > * + * {
  margin-left: 10px;
}

.column {
  flex-grow: 5;
  flex-basis: 0;
  overflow: hidden;
}

.column:first-child {
  flex-grow: 10;
}

.column:nth-child(4) {
  flex-grow: 0;
  flex-basis: auto;
}

.striked {
  text-decoration: line-through;
}

.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: capitalize;
  height: 40px;
}

.user > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user > * + * {
  margin-top: 3px;
}

.user :last-child {
  font-size: 11px;
  color: #757575;
}

.user :first-child {
  font-size: 12px;
  color: #212121;
  font-weight: var(--font-weight-semi);
}

.user :first-child:last-child {
  font-size: 13px;
}

.provider {
  height: 28px;
  display: flex;
}
.provider span {
  display: block;
  height: 28px;
  line-height: 28px;
  padding: 0 8px;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 999px;
  background: #eeeeee;
  color: #424242;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.provider-bankid span,
.provider-2fa span {
  background: #69f0ae;
  color: #1b5e20;
}

.role {
  height: 28px;
  display: flex;
}
.role span {
  display: block;
  height: 28px;
  line-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: 999px;
  background: #eeeeee;
  color: #424242;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.check :global(.bp3-switch) {
  margin: 0 !important;
  padding: 0 !important;
}
.check :global .bp3-control-indicator {
  margin-left: 0 !important;
  margin-right: 0 !important;
  outline: 0 !important;
}

.check :global .bp3-switch input:checked ~ .bp3-control-indicator {
  background-color: #66bb6a !important;
}

.check :global .bp3-switch input:checked:disabled ~ .bp3-control-indicator {
  background-color: #c8e6c9 !important;
}

.restrictDialog {
  padding: 1rem;
}

.dialogTitle {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #1f2937;
  font-weight: 400;
}

.dialogBody {
  padding: 0.75rem;
  padding-top: 2rem;
}

.dialogBody > * + * {
  margin-top: 2.5rem;
}

.splash {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rest {
  height: calc(100% - 55px);
  overflow: auto;
}

.empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #263238;
  text-align: center;
}

.empty > :first-child {
  color: #ff5252;
  color: #2196f3;
}

.empty > :last-child {
  color: #757575;
  font-size: 13px;
}

.empty > :last-child > :first-child {
  color: #212121;
  padding: 15px 0 10px 0;
  font-size: 19px;
  font-weight: var(--font-weight-bold);
}

.upsideDown {
  transform: rotate(180deg);
}
