.outer {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}

.outer > :first-child,
.outer > :last-child {
  flex-shrink: 0;
}

.outer [data-text] {
  font-size: 16px;
  line-height: 20px;
}

.outer [data-contents] [data-text] {
  font-weight: inherit;
}

.outer [data-contents] h4 [data-text] {
  font-size: 18px;
  line-height: 21px;
  font-weight: var(--font-weight-fat);
}
.outer [data-contents] h5 [data-text] {
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--font-weight-fat);
}
.outer [data-contents] h6 [data-text] {
  font-size: 14px;
  line-height: 16px;
  font-weight: var(--font-weight-fat);
}

.outer [data-contents] ul:first-child,
.outer [data-contents] ol:first-child {
  margin-top: 0;
}
.outer [data-contents] ol:last-child,
.outer [data-contents] ul:last-child {
  margin-bottom: 0;
}
.outer [data-contents] h4:last-child,
.outer [data-contents] h5:last-child,
.outer [data-contents] h6:last-child {
  margin: 0;
}
.outer
  [contenteditable="false"]
  [data-contents]
  [data-block]:first-child
  [data-text]:empty,
.outer
  [contenteditable="false"]
  [data-contents]
  [data-block]:last-child
  [data-text]:empty {
  display: none;
}
