.info {
  height: 300px;
  display: block;
  margin: 15px auto;
}

.some-button {
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 50px;
  border-radius: 15px;
  cursor: pointer;
  overflow: hidden;
  background: #f5f5f5;
}

.some-button:hover {
  background: #eee;
}

.some-button:active {
  background: #ddd;
}

.some-button > :first-child {
  margin: 0 10px 0 20px;
  color: #555;
}
.some-button > :last-child {
  flex-shrink: 0;
  white-space: nowrap;
  padding: 10px;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  color: #444;
}
