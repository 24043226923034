.outer {
  height: 100%;
  display: flex;
  flex-direction: column;
  /*overflow: auto;*/
}

.option {
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
}

.option:not(:first-child) {
  margin-top: 10px;
}

.option:not(.active):not(.inactive):hover {
  background: #e2e8f0;
  background: #f3f4f6;
}

.option.active {
  background: #e2e8f0;
  background: #e8f0fe;
  color: #1967d2;
}
.option.active svg {
  stroke: #1967d2;
}

/*
.option:hover .name,
.option.active .name {
  background: white;
}
*/

.option:not(.inactive):hover .name {
  background: #f3f4f6;
  background: white;
}
.option.active .name {
  background: white;
  color: #1967d2;
}

.group {
  display: flex;
  padding: 10px;
  align-items: center;
}

.group > :first-child {
  flex-shrink: 0;
  margin-right: 10px;
}

.group > :last-child {
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.names {
  display: flex;
  flex-grow: 1;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
}

.name {
  display: flex;
  margin: 5px;
  flex-direction: column;
  justify-content: center;
  text-transform: capitalize;
  background: #e2e8f0;
  background: #eceff1;
  background: #e2e8f0;
  background: #eceff1;
  border-radius: 999px;
  height: 30px;
  padding: 5px 10px;
  color: #546e7a;
  white-space: nowrap;
}

.name :first-child {
  font-size: 11px;
  font-weight: var(--font-weight-semi);
}

.name :last-child {
  font-size: 10px;
}

.name :first-child:last-child {
  font-size: 13px;
}
