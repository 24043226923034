.uploadHeader {
  margin: 10px;
}

.cropContainer {
  position: relative;
  justify-content: center;
  align-items: center;
  height: 400px;
  background: darkgray;
}

.buttonContainer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
}

.errorMessage {
  color: red;
  margin-top: 10px;
  margin-bottom: 0;
  margin-left: 20px;
}
