.outer {
  height: 100%;
}

.outer * {
  user-select: text;
}

.tabs {
  display: flex;
  height: 50px;
  align-items: center;
}

.tab {
  margin: 3px 6px;
  padding: 10px;
  font-size: 11px;
  font-weight: 500;
}

.tab:hover {
  border-radius: 5px;
  background: #eee;
  cursor: pointer;
}

.content {
  height: calc(100% - 50px);
  overflow: auto;
}

.home {
  padding: 10px;
  font-size: 30px;
}

.contextInspect {
  padding: 10px;
  font-size: 10px;
}

.graphState {
  padding: 10px;
  font-size: 10px;
}

.trustInspect {
  padding: 10px;
}

.trustState {
  padding: 10px;
  font-size: 10px;
}

.trustInspect .spec {
  padding: 10px;
  font-size: 16px;
}

.trustInspect .group {
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  background: #eee;
}

.trustInspect .group:hover {
  cursor: pointer;
  background: #ddd;
}

.trustInspect .group > :first-child {
  flex-grow: 1;
}

.trustInspect .group > :last-child > * {
  background: #ea80fc;
  border-radius: 3px;
  padding: 2px 4px;
  color: white;
}

.trustInspect .group > :last-child > * + * {
  margin-top: 5px;
}

.trustInspect .group :first-child :first-child {
  font-weight: 500;
}

.trustInspect .group :first-child :last-child {
  font-size: 10px;
}

.trustInspect pre {
  font-size: 10px;
}

.table,
.table > div {
  border: solid 1px #ddd;
}

.table {
  font-size: 9px;
  display: flex;
  flex-wrap: wrap;
  background: red;
  border-top: none;
  border-right: none;
}

.table > div {
  width: 25%;
  padding: 1px;
  background: white;
  border-bottom: none;
  border-left: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.table > div:nth-child(-n + 4) {
  font-weight: bold;
}

.detailed pre {
  font-size: 9px;
}

.topics {
  display: flex;
  flex-direction: column;
  height: calc(100% - 100px);
}

.topics textarea {
  height: 100%;
  font-size: 9px;
}
